import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    advancedConfigToggle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginTop: theme.spacing(2),
        fontWeight: "bold",
        color: theme.palette.primary.main,
    },
    advancedConfig: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[100],
    },
    response: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
        whiteSpace: "pre-wrap",
        fontFamily: "monospace",
        fontSize: "0.875rem",
    },
    variableSelector: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #ddd",
    },
    loading: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(2),
    },
}));

const FlowBuilderHttpModal = ({ open, onSave, data, onUpdate, close }) => {
    const classes = useStyles();
    const [url, setUrl] = useState("");
    const [method, setMethod] = useState("GET");
    const [queryParams, setQueryParams] = useState("");
    const [headers, setHeaders] = useState("");
    const [body, setBody] = useState("");
    const [response, setResponse] = useState(null);
    const [variables, setVariables] = useState([]);
    const [selectedVariables, setSelectedVariables] = useState({});
    const [loading, setLoading] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [selectedVariable, setSelectedVariable] = useState("");
    const [variableName, setVariableName] = useState("");
    const [savedVariables, setSavedVariables] = useState([]);

    useEffect(() => {
        if (open === "create") {
            // Resetar estados para novo request
            setUrl("");
            setMethod("GET");
            setQueryParams("");
            setHeaders("");
            setBody("");
            setResponse(null);
            setVariables([]);
            setSavedVariables([]); // Limpa as variáveis salvas
            setSelectedVariables({});
        } else if (open === "edit" && data) {
            // Navega pela estrutura dos dados corretamente
            const { url, method, queryParams, headers, body, savedVariables } = data?.data?.data || data?.data || data;
            ////console.log("🚀 ~ useEffect ~ data:", data)
            setUrl(url || "");
            setMethod(method || "GET");
            setQueryParams(queryParams ? JSON.stringify(queryParams) : "");
            setHeaders(headers ? JSON.stringify(headers) : "");
            setBody(body ? JSON.stringify(body) : "");
            setSavedVariables(savedVariables || []);
        }
    }, [open, data]);



    const handleRequest = async () => {
        setLoading(true);
        try {
            const proxyUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/proxy`;

            const config = {
                method: "POST",
                url: proxyUrl,
                data: {
                    targetUrl: url,
                    method,
                    headers: headers ? JSON.parse(headers) : undefined,
                    params: queryParams ? JSON.parse(queryParams) : undefined,
                    data: body ? JSON.parse(body) : undefined
                },
                withCredentials: false
            };

            const res = await api(config);

            setResponse(res.data);
            extractVariables(res.data);
            toast.success("Request successful!");
        } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to execute request.");
            setResponse(null);
            setVariables([]);
        } finally {
            setLoading(false);
        }
    };

    const extractVariables = (data) => {
        const variables = [];
        const traverse = (obj, path = "") => {
            if (obj && typeof obj === "object") {
                Object.keys(obj).forEach((key) => {
                    traverse(obj[key], path ? `${path}.${key}` : key);
                });
            } else {
                variables.push({ path, value: obj });
            }
        };
        traverse(data);
        setVariables(variables);
    };

    const handleVariableSelect = (path, variableName) => {
        setSelectedVariables((prev) => ({
            ...prev,
            [path]: variableName,
        }));
    };

    const handleAddVariable = () => {
        if (!selectedVariable || !variableName) {
            toast.error("Selecione uma variável e dê um nome a ela.");
            return;
        }
        setSavedVariables((prev) => [
            ...prev,
            { path: selectedVariable, name: variableName },
        ]);
        setSelectedVariable("");
        setVariableName("");
    };

    const handleDeleteVariable = (variableName) => {
        setSavedVariables((prev) =>
            prev.filter((variable) => variable.name !== variableName)
        );
    };

    const handleSave = () => {
        const requestData = {
            url,
            method,
            queryParams: queryParams ? JSON.parse(queryParams) : null,
            headers: headers ? JSON.parse(headers) : null,
            body: body ? JSON.parse(body) : null,
            savedVariables,
        };

        if (open === "edit") {
            onUpdate({
                ...data,
                data: requestData,
            });
        } else if (open === "create") {
            onSave({ data: requestData });
        }

        handleClose();
    };

    const handleClose = () => {
        setUrl("");
        setMethod("GET");
        setQueryParams("");
        setHeaders("");
        setBody("");
        setResponse(null);
        setVariables([]);
        setSelectedVariables({});
        setShowAdvanced(false);
        close(null);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth="md" scroll="paper">
            <DialogTitle>HTTP Request</DialogTitle>
            <DialogContent dividers>
                <div className={classes.root}>
                    <TextField
                        label="URL"
                        variant="outlined"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        fullWidth
                    />
                    <Select
                        value={method}
                        onChange={(e) => setMethod(e.target.value)}
                        fullWidth
                        variant="outlined"
                    >
                        <MenuItem value="GET">GET</MenuItem>
                        <MenuItem value="POST">POST</MenuItem>
                        <MenuItem value="PUT">PUT</MenuItem>
                        <MenuItem value="DELETE">DELETE</MenuItem>
                    </Select>
                    <Button
                        onClick={() => setShowAdvanced(!showAdvanced)}
                        variant="text"
                        style={{ textAlign: "center", color: "#3aba38" }}
                    >
                        {showAdvanced ? (
                            <>
                                Ocultar Configuração Avançada <ExpandLessIcon />
                            </>
                        ) : (
                            <>
                                Mostrar Configuração Avançada <ExpandMoreIcon />
                            </>
                        )}
                    </Button>
                    {showAdvanced && (
                        <>
                            <TextField
                                label="Query Parameters (JSON)"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={queryParams}
                                onChange={(e) => setQueryParams(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                label="Headers (JSON)"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={headers}
                                onChange={(e) => setHeaders(e.target.value)}
                                fullWidth
                            />
                            {["POST", "PUT"].includes(method) && (
                                <TextField
                                    label="Body (JSON)"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={body}
                                    onChange={(e) => setBody(e.target.value)}
                                    fullWidth
                                />
                            )}
                        </>
                    )}
                    {response && (
                        <div className={classes.variableSelector}>
                            <Typography variant="h6">Selecione a Variável</Typography>
                            <Select
                                value={selectedVariable}
                                onChange={(e) => setSelectedVariable(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="">Selecione uma Variável</MenuItem>
                                {variables.map((variable, index) => (
                                    <MenuItem key={index} value={variable.path}>
                                        {variable.path}: {JSON.stringify(variable.value)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <TextField
                                label="Nome da variável"
                                variant="outlined"
                                value={variableName}
                                onChange={(e) => setVariableName(e.target.value)}
                                fullWidth
                                style={{ marginTop: "8px" }}
                            />
                            <Button
                                onClick={handleAddVariable}
                                color="primary"
                                variant="outlined"
                                style={{ marginTop: "8px" }}
                            >
                                Add Variável
                            </Button>
                        </div>
                    )}
                    {savedVariables.length > 0 && (
                        <div className={classes.response}>
                            <Typography variant="h6">Variáveis Salvas</Typography>
                            {savedVariables.map((variable, index) => (
                                <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="body2">
                                        {variable.name} ({variable.path})
                                    </Typography>
                                    <Button
                                        color="secondary"
                                        size="small"
                                        onClick={() => handleDeleteVariable(variable.name)}
                                    >
                                        Excluir
                                    </Button>
                                </div>
                            ))}
                        </div>
                    )}
                    {loading && (
                        <div className={classes.loading}>
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleRequest}
                    color="primary"
                    variant="contained"
                    disabled={loading}
                >
                    Testar Request
                </Button>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancelar
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FlowBuilderHttpModal;

