import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Paper,
    Grid,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import { Formik, Form, Field } from 'formik';
import ButtonWithSpinner from "../ButtonWithSpinner";
import ConfirmationModal from "../ConfirmationModal";

import { Edit as EditIcon } from "@material-ui/icons";

import { toast } from "react-toastify";
import usePlans from "../../hooks/usePlans";
import { i18n } from "../../translate/i18n";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    mainPaper: {
        width: '100%',
        flex: 1,
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    fullWidth: {
        width: '100%'
    },
    tableContainer: {
        width: '100%',
        overflowX: "auto",
        marginTop: theme.spacing(2),
        ...theme.scrollbarStyles
    },
    textfield: {
        width: '100%'
    },
    textRight: {
        textAlign: 'right'
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    control: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    buttonContainer: {
        textAlign: 'right',
        padding: theme.spacing(1)
    },
    tableCell: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5),
        },
    }
}));

export function PlanManagerForm(props) {
    const { onSubmit, onDelete, onCancel, initialValue, loading } = props;
    const classes = useStyles()

    const [record, setRecord] = useState({
        name: '',
        users: 0,
        connections: 0,
        connectionsFacebook: 0,
        connectionsInstagram: 0,
        connectionsApiOficial: 0,
        queues: 0,
        value: 0,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useKanban: true,
        useOpenAi: true,
        useIntegrations: true,
        useAutomation: true,
        useFlow: true,
        useFacebook: true,
        useInstagram: true,
        usePowerCrm: true,
        useRemarketing: true,
        useTelegram: true,
        useApiOficial: true,
        showSignup: true,
    });

    useEffect(() => {
        setRecord(initialValue)
    }, [initialValue])

    const handleSubmit = async (data) => {
        onSubmit(data)
    }

    return (
        <Formik
            enableReinitialize
            className={classes.fullWidth}
            initialValues={record}
            onSubmit={(values, { resetForm }) =>
                setTimeout(() => {
                    handleSubmit(values)
                    resetForm()
                }, 500)
            }
        >
            {(values) => (
                <Form className={classes.fullWidth}>
                    <Grid container spacing={2}>
                        {/* NOME */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label={i18n.t("plans.form.name")}
                                name="name"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                            />
                        </Grid>

                        {/* USUARIOS */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label={i18n.t("plans.form.users")}
                                name="users"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* FILAS */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Filas"
                                name="queues"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* VALOR */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Valor"
                                name="value"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="text"
                            />
                        </Grid>

                        {/* CONEXOES WHATSAPP SOCKET*/}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Conexões Whatsapp"
                                name="connections"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* FACEBOOK */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useFacebook-selection">Facebook</InputLabel>
                                <Field
                                    as={Select}
                                    id="useFacebook-selection"
                                    label="Facebook"
                                    name="useFacebook"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        {/* CONEXOES FACEBOOK */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Conexões Facebook"
                                name="connectionsFacebook"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>
                        {/* INSTAGRAM */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useInstagram-selection">Instagram</InputLabel>
                                <Field
                                    as={Select}
                                    id="useInstagram-selection"
                                    label="Instagram"
                                    name="useInstagram"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        {/* CONEXOES INSTAGRAM */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Conexões Instagram"
                                name="connectionsInstagram"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* API OFICIAL */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useApiOficial-selection">API Oficial</InputLabel>
                                <Field
                                    as={Select}
                                    id="useApiOficial-selection"
                                    label="API Oficial"
                                    name="useApiOficial"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* CONEXOES API OFICIAL */}
                        <Grid item xs={12} sm={6} md={2}>
                            <Field
                                as={TextField}
                                label="Conexões API Oficial"
                                name="connectionsApiOficial"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* TELEGRAM */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useTelegram-selection">Telegram</InputLabel>
                                <Field
                                    as={Select}
                                    id="useTelegram-selection"
                                    label="Telegram"
                                    name="useTelegram"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* CAMPANHAS */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useCampaigns-selection">{i18n.t("plans.form.campaigns")}</InputLabel>
                                <Field
                                    as={Select}
                                    id="useCampaigns-selection"
                                    label={i18n.t("plans.form.campaigns")}
                                    name="useCampaigns"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* AGENDAMENTOS */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useSchedules-selection">{i18n.t("plans.form.schedules")}</InputLabel>
                                <Field
                                    as={Select}
                                    id="useSchedules-selection"
                                    label={i18n.t("plans.form.schedules")}
                                    name="useSchedules"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* CHAT INTERNO */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useInternalChat-selection">Chat Interno</InputLabel>
                                <Field
                                    as={Select}
                                    id="useInternalChat-selection"
                                    label="Chat Interno"
                                    name="useInternalChat"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* API Externa */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useExternalApi-selection">API Externa</InputLabel>
                                <Field
                                    as={Select}
                                    id="useExternalApi-selection"
                                    label="API Externa"
                                    name="useExternalApi"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* KANBAN */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useKanban-selection">Kanban</InputLabel>
                                <Field
                                    as={Select}
                                    id="useKanban-selection"
                                    label="Kanban"
                                    name="useKanban"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* OPENAI */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useOpenAi-selection">Open.Ai</InputLabel>
                                <Field
                                    as={Select}
                                    id="useOpenAi-selection"
                                    label="Talk.Ai"
                                    name="useOpenAi"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* INTEGRACOES */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useIntegrations-selection">Integrações</InputLabel>
                                <Field
                                    as={Select}
                                    id="useIntegrations-selection"
                                    label="Integrações"
                                    name="useIntegrations"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* AUTOMATION */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useAutomation-selection">Automação</InputLabel>
                                <Field
                                    as={Select}
                                    id="useAutomation-selection"
                                    label="Automações"
                                    name="useAutomation"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* FLOW */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useFlow-selection">Flow Builder</InputLabel>
                                <Field
                                    as={Select}
                                    id="useFlow-selection"
                                    label="Automações"
                                    name="useFlow"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* POWER CRM */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="usePowerCrm-selection">Power CRM</InputLabel>
                                <Field
                                    as={Select}
                                    id="usePowerCrm-selection"
                                    label="Automações"
                                    name="usePowerCrm"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* REMARKETING */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="useRemarketing-selection">Remarketing</InputLabel>
                                <Field
                                    as={Select}
                                    id="useRemarketing-selection"
                                    label="Automações"
                                    name="useRemarketing"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        {/* MOSTRA NO CADASTRO */}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl margin="dense" variant="outlined" className={classes.fullWidth}>
                                <InputLabel htmlFor="showSignup-selection">Mostrar no Cadastro</InputLabel>
                                <Field
                                    as={Select}
                                    id="showSignup-selection"
                                    label="Mostrar no Cadastro"
                                    name="showSignup"
                                >
                                    <MenuItem value={true}>{i18n.t("plans.form.enabled")}</MenuItem>
                                    <MenuItem value={false}>{i18n.t("plans.form.disabled")}</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item xs={12} sm={4} md={2}>
                            <ButtonWithSpinner className={classes.fullWidth} loading={loading} onClick={() => onCancel()} variant="contained">
                                {i18n.t("plans.form.clear")}
                            </ButtonWithSpinner>
                        </Grid>
                        {record.id !== undefined ? (
                            <Grid item xs={12} sm={4} md={2}>
                                <ButtonWithSpinner className={classes.fullWidth} loading={loading} onClick={() => onDelete(record)} variant="contained" color="secondary">
                                    {i18n.t("plans.form.delete")}
                                </ButtonWithSpinner>
                            </Grid>
                        ) : null}
                        <Grid item xs={12} sm={4} md={2}>
                            <ButtonWithSpinner className={classes.fullWidth} loading={loading} type="submit" variant="contained" color="primary">
                                {i18n.t("plans.form.save")}
                            </ButtonWithSpinner>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export function PlansManagerGrid(props) {
    const { records, onSelect } = props
    const classes = useStyles()

    const renderCampaigns = (row) => {
        return row.useCampaigns === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderFacebook = (row) => {
        return row.useFacebook === false ? "Não" : "Sim";
    };

    const renderInstagram = (row) => {
        return row.useInstagram === false ? "Não" : "Sim";
    };

    const renderSchedules = (row) => {
        return row.useSchedules === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderInternalChat = (row) => {
        return row.useInternalChat === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderExternalApi = (row) => {
        return row.useExternalApi === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderKanban = (row) => {
        return row.useKanban === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderOpenAi = (row) => {
        return row.useOpenAi === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderIntegrations = (row) => {
        return row.useIntegrations === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderAutomations = (row) => {
        return row.useAutomation === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderFlow = (row) => {
        return row.useFlow === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderPowerCrm = (row) => {
        return row.usePowerCrm === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderRemarketing = (row) => {
        return row.useRemarketing === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderTelegram = (row) => {
        return row.useTelegram === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderApiOficial = (row) => {
        return row.useApiOficial === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const rendershowSignup = (row) => {
        return row.showSignup === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    return (
        <Paper className={classes.tableContainer}>
            <Table className={classes.fullWidth} aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.tableCell} style={{ width: '1%' }}>#</TableCell>
                        <TableCell align="left" className={classes.tableCell}>{i18n.t("plans.form.name")}</TableCell>
                        <TableCell align="center" className={classes.tableCell}>{i18n.t("plans.form.users")}</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Conexões Whatsapp</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Conexões Facebook</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Conexões Instagram</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Conexões Api Oficial</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Filas</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Valor</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Facebook</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Instagram</TableCell>
                        <TableCell align="center" className={classes.tableCell}>API Oficial</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Telegram</TableCell>
                        <TableCell align="center" className={classes.tableCell}>{i18n.t("plans.form.schedules")}</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Chat Interno</TableCell>
                        <TableCell align="center" className={classes.tableCell}>API Externa</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Kanban</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Open.Ai</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Integrações</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Automações</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Flow Builder</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Power Crm</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Remarketing</TableCell>
                        <TableCell align="center" className={classes.tableCell}>Mostrar no Cadastro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="center" className={classes.tableCell} style={{ width: '1%' }}>
                                <IconButton onClick={() => onSelect(row)} aria-label="delete">
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>{row.name || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.users || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.connections || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.connectionsFacebook || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.connectionsInstagram || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.connectionsApiOficial || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{row.queues || '-'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{i18n.t("plans.form.money")} {row.value ? row.value.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '00.00'}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderFacebook(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderInstagram(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderApiOficial(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderTelegram(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderSchedules(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderInternalChat(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderExternalApi(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderKanban(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderOpenAi(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderIntegrations(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderAutomations(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderFlow(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderPowerCrm(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{renderRemarketing(row)}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{rendershowSignup(row)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

export default function PlansManager() {
    const classes = useStyles()
    const { list, save, update, remove } = usePlans()

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState([])
    const [record, setRecord] = useState({
        name: '',
        users: 0,
        connections: 0,
        connectionsFacebook: 0,
        connectionsInstagram: 0,
        connectionsApiOficial: 0,
        queues: 0,
        value: 0,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useKanban: true,
        useOpenAi: true,
        useIntegrations: true,
        useAutomation: true,
        useFlow: true,
        useFacebook: true,
        useInstagram: true,
        usePowerCrm: true,
        useRemarketing: true,
        useTelegram: true,
        useApiOficial: true,
        showSignup: true,
    })

    useEffect(() => {
        async function fetchData() {
            await loadPlans()
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    const loadPlans = async () => {
        setLoading(true)
        try {
            const planList = await list()
            setRecords(planList)
        } catch (e) {
            toast.error('Não foi possível carregar a lista de registros')
        }
        setLoading(false)
    }

    const handleSubmit = async (data) => {
        setLoading(true)
        try {
            if (data.id !== undefined) {
                await update(data)
            } else {
                await save(data)
            }
            await loadPlans()
            handleCancel()
            toast.success('Operação realizada com sucesso!')
        } catch (e) {
            toast.error('Não foi possível realizar a operação. Verifique se já existe uma plano com o mesmo nome ou se os campos foram preenchidos corretamente')
        }
        setLoading(false)
    }

    const handleDelete = async () => {
        setLoading(true)
        try {
            await remove(record.id)
            await loadPlans()
            handleCancel()
            toast.success('Operação realizada com sucesso!')
        } catch (e) {
            toast.error('Não foi possível realizar a operação')
        }
        setLoading(false)
    }

    const handleOpenDeleteDialog = () => {
        setShowConfirmDialog(true)
    }

    const handleCancel = () => {
        setRecord({
            id: undefined,
            name: '',
            users: 0,
            connections: 0,
            connectionsFacebook: 0,
            connectionsInstagram: 0,
            connectionsApiOficial: 0,
            queues: 0,
            value: 0,
            useCampaigns: true,
            useSchedules: true,
            useInternalChat: true,
            useExternalApi: true,
            useKanban: true,
            useOpenAi: true,
            useIntegrations: true,
            useAutomation: true,
            useFlow: true,
            useFacebook: true,
            useInstagram: true,
            usePowerCrm: true,
            useRemarketing: true,
            useTelegram: true,
            useApiOficial: true,
            showSignup: true,
        })
    }

    const handleSelect = (data) => {

        let useCampaigns = data.useCampaigns === false ? false : true
        let useSchedules = data.useSchedules === false ? false : true
        let useInternalChat = data.useInternalChat === false ? false : true
        let useExternalApi = data.useExternalApi === false ? false : true
        let useKanban = data.useKanban === false ? false : true
        let useOpenAi = data.useOpenAi === false ? false : true
        let useIntegrations = data.useIntegrations === false ? false : true
        let useAutomation = data.useAutomation === false ? false : true
        let useFlow = data.useFlow === false ? false : true
        let useFacebook = data.useFacebook === false ? false : true
        let useInstagram = data.useInstagram === false ? false : true
        let usePowerCrm = data.usePowerCrm === false ? false : true
        let useRemarketing = data.useRemarketing === false ? false : true
        let useTelegram = data.useTelegram === false ? false : true
        let useApiOficial = data.useApiOficial === false ? false : true
        let showSignup = data.showSignup === false ? false : true

        //console.log(data.connectionsFacebook)
        setRecord({
            id: data.id,
            name: data.name || '',
            users: data.users || 0,
            connections: data.connections || 0,
            connectionsFacebook: data.connectionsFacebook || 0,
            connectionsInstagram: data.connectionsInstagram || 0,
            connectionsApiOficial: data.connectionsApiOficial || 0,
            queues: data.queues || 0,
            value: data.value?.toLocaleString('pt-br', { minimumFractionDigits: 0 }) || 0,
            useFacebook,
            useInstagram,
            useCampaigns,
            useSchedules,
            useInternalChat,
            useExternalApi,
            useKanban,
            useOpenAi,
            useIntegrations,
            useAutomation,
            useFlow,
            usePowerCrm,
            useRemarketing,
            useTelegram,
            useApiOficial,
            showSignup
        })
    }

    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PlanManagerForm
                        initialValue={record}
                        onDelete={handleOpenDeleteDialog}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PlansManagerGrid
                        records={records}
                        onSelect={handleSelect}
                    />
                </Grid>
            </Grid>
            <ConfirmationModal
                title="Exclusão de Registro"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => handleDelete()}
            >
                Deseja realmente excluir esse registro?
            </ConfirmationModal>
        </Paper>
    )
}
