import React, { useEffect, useState, useContext } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Title from "../Title";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import useSettings from "../../hooks/useSettings";
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import { Tabs, Tab } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../translate/i18n";


import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import usePlans from "../../hooks/usePlans/index.js";
import useCompanies from "../../hooks/useCompanies";
import { AuthContext } from "../../context/Auth/AuthContext";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    tab: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
        width: "100%",
        "& .MuiTab-wrapper": {
            color: theme.palette.text.primary,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        width: "100%",
    },
    cardAvatar: {
        fontSize: "55px",
        color: grey[500],
        backgroundColor: "#ffffff",
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: "18px",
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: "14px",
    },
    alignRight: {
        textAlign: "right",
    },
    fullWidth: {
        width: "100%",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    // Adicione um estilo para a box vermelha
    redBox: {
        backgroundColor: "#ffcccc", // Definindo a cor de fundo vermelha
        padding: theme.spacing(2), // Adicionando um espaçamento interno
        marginBottom: theme.spacing(2), // Adicionando margem inferior para separar do conteúdo abaixo
    },
}));



export default function Remarketing(props) {
    const { settings, scheduleTypeChanged } = props;
    const classes = useStyles();

    // Estados individuais para cada formulário
    const [formData1, setFormData1] = useState({
        remarketing1minutes: "",
        remarketing1times: "",
        remarketing1message: ""
    });

    const [formData2, setFormData2] = useState({
        remarketing2minutes: "",
        remarketing2times: "",
        remarketing2message: ""
    });

    const [formData3, setFormData3] = useState({
        remarketing3minutes: "",
        remarketing3times: "",
        remarketing3message: ""
    });


    const { finder, getPlanCompany } = usePlans();
    const { find, updateSchedules } = useCompanies();
    const { user } = useContext(AuthContext);
    const [remarketingType, setRemarketingType] = useState("");
    const [loadingRemarketingType, setLoadingRemarketingType] = useState(false);
    const [remarketingTypeUpdated, setRemarketingTypeUpdated] = useState(false);
    const [whatsapps, setWhatsapps] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [isBusinessAccount, setIsBusinessAccount] = useState(false);
    const [selectedWhatsAppId, setSelectedWhatsAppId] = useState(null);
    const [templatesByWhatsAppId, setTemplatesByWhatsAppId] = useState({});
    const [selectedTemplates1, setSelectedTemplates1] = useState({});
    const [selectedTemplates2, setSelectedTemplates2] = useState({});
    const [selectedTemplates3, setSelectedTemplates3] = useState({});
    const [templatesByWhatsAppId1, setTemplatesByWhatsAppId1] = useState({});
    const [templatesByWhatsAppId2, setTemplatesByWhatsAppId2] = useState({});
    const [templatesByWhatsAppId3, setTemplatesByWhatsAppId3] = useState({});
    const { update } = useSettings();


    useEffect(() => {
        const fetchWhatsappsAndTemplates = async () => {
            try {
                const { companyId } = user;
                const { data: whatsappsData } = await api.get(`/whatsapp`, { params: { channel: "whatsapp_business_account", companyId } });
                setWhatsapps(whatsappsData);

                // Carrega os templates para cada WhatsApp e armazena no estado
                const templatesPromises = whatsappsData.map(async (whatsapp) => {
                    const { data: templates } = await api.get(`/whatsapp/listTemplate/${whatsapp.id}`);
                    return { [whatsapp.id]: templates };
                });

                const templatesArray = await Promise.all(templatesPromises);
                const templatesById = Object.assign({}, ...templatesArray);
                setTemplatesByWhatsAppId1(templatesById);
                setTemplatesByWhatsAppId2(templatesById);
                setTemplatesByWhatsAppId3(templatesById);

            } catch (err) {
                toastError("Erro ao carregar conexões de WhatsApp ou templates");
            }
        };

        fetchWhatsappsAndTemplates();
    }, [user]);


    useEffect(() => {
        const remarketing1json = settings.find((s) => s.key === "remarketing1json")?.value;
        if (remarketing1json) {
            try {
                const parsedJson = JSON.parse(remarketing1json);
                const templates = parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates || [];
                    return acc;
                }, {});
                setTemplatesByWhatsAppId1(templates);
                setSelectedTemplates1(parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates[0]?.id || "";
                    return acc;
                }, {}));
            } catch (error) {
                console.error("Erro ao parsear o JSON de remarketing1json:", error);
            }
        }
    }, [settings]);

    useEffect(() => {
        const remarketing2json = settings.find((s) => s.key === "remarketing2json")?.value;
        if (remarketing2json) {
            try {
                const parsedJson = JSON.parse(remarketing2json);
                const templates = parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates || [];
                    return acc;
                }, {});
                setTemplatesByWhatsAppId2(templates);
                setSelectedTemplates2(parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates[0]?.id || "";
                    return acc;
                }, {}));
            } catch (error) {
                console.error("Erro ao parsear o JSON de remarketing2json:", error);
            }
        }
    }, [settings]);

    useEffect(() => {
        const remarketing3json = settings.find((s) => s.key === "remarketing3json")?.value;
        if (remarketing3json) {
            try {
                const parsedJson = JSON.parse(remarketing3json);
                const templates = parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates || [];
                    return acc;
                }, {});
                setTemplatesByWhatsAppId3(templates);
                setSelectedTemplates3(parsedJson.whatsapps.reduce((acc, whatsapp) => {
                    acc[whatsapp.id] = whatsapp.templates[0]?.id || "";
                    return acc;
                }, {}));
            } catch (error) {
                console.error("Erro ao parsear o JSON de remarketing3json:", error);
            }
        }
    }, [settings]);
    const handleTemplateChange1 = (whatsappId, selectedTemplateId) => {
        setSelectedTemplates1((prev) => ({
            ...prev,
            [whatsappId]: selectedTemplateId
        }));
    };

    const handleTemplateChange2 = (whatsappId, selectedTemplateId) => {
        setSelectedTemplates2((prev) => ({
            ...prev,
            [whatsappId]: selectedTemplateId
        }));
    };

    const handleTemplateChange3 = (whatsappId, selectedTemplateId) => {
        setSelectedTemplates3((prev) => ({
            ...prev,
            [whatsappId]: selectedTemplateId
        }));
    };


    useEffect(() => {
        if (Array.isArray(settings) && settings.length) {
            const remarketing1minutes = settings.find((s) => s.key === "remarketing1minutes");
            const remarketing1times = settings.find((s) => s.key === "remarketing1times");
            const remarketing1message = settings.find((s) => s.key === "remarketing1message");

            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData1(prevState => ({
                ...prevState,
                remarketing1minutes: remarketing1minutes?.value || "",
                remarketing1times: remarketing1times?.value || "",
                remarketing1message: remarketing1message?.value || ""
            }));

            const remarketing2minutes = settings.find((s) => s.key === "remarketing2minutes");
            const remarketing2times = settings.find((s) => s.key === "remarketing2times");
            const remarketing2message = settings.find((s) => s.key === "remarketing2message");

            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData2(prevState => ({
                ...prevState,
                remarketing2minutes: remarketing2minutes?.value || "",
                remarketing2times: remarketing2times?.value || "",
                remarketing2message: remarketing2message?.value || ""
            }));


            const remarketing3minutes = settings.find((s) => s.key === "remarketing3minutes");
            const remarketing3times = settings.find((s) => s.key === "remarketing3times");
            const remarketing3message = settings.find((s) => s.key === "remarketing3message");


            // Atualiza somente remarketing1Minutes no estado formData1
            setFormData3(prevState => ({
                ...prevState,
                remarketing3minutes: remarketing3minutes?.value || "",
                remarketing3times: remarketing3times?.value || "",
                remarketing3message: remarketing3message?.value || ""
            }));


        }
    }, [settings]);


    const handleSaveForm1 = async (values, actions) => {
        const remarketingJson = {
            whatsapps: whatsapps.map((whatsapp) => ({
                id: whatsapp.id,
                name: whatsapp.name,
                // Usa o valor selecionado atual no JSON
                templates: templatesByWhatsAppId1[whatsapp.id]?.filter(template => template.id === selectedTemplates1[whatsapp.id]) || []
            }))
        };

        try {
            await update({
                key: "remarketing1json",
                value: JSON.stringify(remarketingJson)
            });
            await update({
                key: "remarketing1minutes",
                value: values.remarketing1minutes,
            });
            await update({
                key: "remarketing1times",
                value: values.remarketing1times,
            });
            await update({
                key: "remarketing1message",
                value: values.remarketing1message,
            });
            toast.success("Dados da Primeira Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    const handleSaveForm2 = async (values, actions) => {
        const remarketingJson = {
            whatsapps: whatsapps.map((whatsapp) => ({
                id: whatsapp.id,
                name: whatsapp.name,
                templates: templatesByWhatsAppId2[whatsapp.id]?.filter(template => template.id === selectedTemplates2[whatsapp.id]) || []
            }))
        };

        try {
            await update({
                key: "remarketing2json",
                value: JSON.stringify(remarketingJson)
            });
            await update({
                key: "remarketing2minutes",
                value: values.remarketing2minutes,
            });
            await update({
                key: "remarketing2times",
                value: values.remarketing2times,
            });
            await update({
                key: "remarketing2message",
                value: values.remarketing2message,
            });
            toast.success("Dados da Segunda Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    const handleSaveForm3 = async (values, actions) => {
        const remarketingJson = {
            whatsapps: whatsapps.map((whatsapp) => ({
                id: whatsapp.id,
                name: whatsapp.name,
                templates: templatesByWhatsAppId3[whatsapp.id]?.filter(template => template.id === selectedTemplates3[whatsapp.id]) || []
            }))
        };

        try {
            await update({
                key: "remarketing3json",
                value: JSON.stringify(remarketingJson)
            });
            await update({
                key: "remarketing3minutes",
                value: values.remarketing3minutes,
            });
            await update({
                key: "remarketing3times",
                value: values.remarketing3times,
            });
            await update({
                key: "remarketing3message",
                value: values.remarketing3message,
            });
            toast.success("Dados da Terceira Opção de Remarketing salvos com sucesso!");
        } catch (error) {
            toastError(error);
        }
        actions.setSubmitting(false);
    };

    return (
        <>
            <Grid spacing={3} container style={{ marginBottom: 10 }}>
                <Grid container spacing={2}> {/* Define um Grid container aqui com um espaçamento */}
                    <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.redBox} variant="outlined">
                            <Typography variant="body1" style={{ textAlign: 'center' }}>
                                <strong>Aviso Importante:</strong> Lembre-se de configurar a opção de remarketing no <strong>Fila</strong> ou <strong>Conexão</strong>
                            </Typography>
                        </Paper>

                    </Grid>
                    {/* Primeiro item de Grid para o primeiro formulário */}
                    <Grid item xs={6} sm={6} md={4}> {/* Altere para ocupar metade da largura */}
                        <Formik initialValues={formData1} onSubmit={handleSaveForm1} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Primeira Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing1minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing1times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do primeiro Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing1message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        {whatsapps && whatsapps.map((whatsapp) => (
                                            <FormControl
                                                key={whatsapp.id}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                style={{ marginBottom: 16 }}
                                            >
                                                <InputLabel id={`whatsapp-selection-label-${whatsapp.id}`}>
                                                    {whatsapp.name}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    label={whatsapp.name}
                                                    labelId={`whatsapp-selection-label-${whatsapp.id}`}
                                                    id={`whatsappId-${whatsapp.id}`}
                                                    name={`whatsappId-${whatsapp.id}`}
                                                    value={selectedTemplates1[whatsapp.id] || ""}
                                                    onChange={(event) => handleTemplateChange1(whatsapp.id, event.target.value)}

                                                >
                                                    {templatesByWhatsAppId1[whatsapp.id] && templatesByWhatsAppId1[whatsapp.id].map((template) => (
                                                        <MenuItem key={template.id} value={template.id}>
                                                            {template.name}
                                                        </MenuItem>
                                                    ))}

                                                </Field>
                                            </FormControl>
                                        ))}


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>

                                </Form>
                            )}
                        </Formik>


                    </Grid>

                    {/* Segundo item de Grid para o segundo formulário */}
                    <Grid item xs={6} sm={6} md={4}>
                        <Formik initialValues={formData2} onSubmit={handleSaveForm2} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Segunda Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing2minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing2times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do segundo Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing2message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        {whatsapps && whatsapps.map((whatsapp) => (
                                            <FormControl
                                                key={whatsapp.id}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                style={{ marginBottom: 16 }}
                                            >
                                                <InputLabel id={`whatsapp2-selection-label-${whatsapp.id}`}>
                                                    {whatsapp.name}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    label={whatsapp.name}
                                                    labelId={`whatsapp2-selection-label-${whatsapp.id}`}
                                                    id={`whatsappId2-${whatsapp.id}`}
                                                    name={`whatsappId2-${whatsapp.id}`}
                                                    value={selectedTemplates2[whatsapp.id] || ""}
                                                    onChange={(event) => handleTemplateChange2(whatsapp.id, event.target.value)}

                                                >
                                                    {templatesByWhatsAppId2[whatsapp.id] && templatesByWhatsAppId2[whatsapp.id].map((template) => (
                                                        <MenuItem key={template.id} value={template.id}>
                                                            {template.name}
                                                        </MenuItem>
                                                    ))}

                                                </Field>
                                            </FormControl>
                                        ))}


                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Grid>

                    {/* Terceiro item de Grid para o segundo formulário */}
                    <Grid item xs={6} sm={6} md={4}>
                        <Formik initialValues={formData3} onSubmit={handleSaveForm3} enableReinitialize={true}>
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent dividers>

                                        <h3 style={{ textAlign: 'center' }}>{i18n.t("Terceira Opção de Remarketing")}</h3>
                                        <p style={{ textAlign: 'center' }}>{i18n.t("Preencha os dados para que o sistema possa fazer o remarketing nos locais configurados")}</p>
                                        <Grid spacing={2} container>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("A cada quantos minutos ?")}
                                                    fullWidth
                                                    name="remarketing3minutes"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid xs={12} md={12} item>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Enviar mensagem quantas vezes ?")}
                                                    fullWidth
                                                    name="remarketing3times"
                                                    variant="outlined"
                                                    margin="dense"
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("Mensagem do terceiro Remaketing")}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="remarketing3message"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        {whatsapps && whatsapps.map((whatsapp) => (
                                            <FormControl
                                                key={whatsapp.id}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                style={{ marginBottom: 16 }}
                                            >
                                                <InputLabel id={`whatsapp3-selection-label-${whatsapp.id}`}>
                                                    {whatsapp.name}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    label={whatsapp.name}
                                                    labelId={`whatsapp3-selection-label-${whatsapp.id}`}
                                                    id={`whatsappId3-${whatsapp.id}`}
                                                    name={`whatsappId3-${whatsapp.id}`}
                                                    value={selectedTemplates3[whatsapp.id] || ""}
                                                    onChange={(event) => handleTemplateChange3(whatsapp.id, event.target.value)}

                                                >
                                                    {templatesByWhatsAppId3[whatsapp.id] && templatesByWhatsAppId3[whatsapp.id].map((template) => (
                                                        <MenuItem key={template.id} value={template.id}>
                                                            {template.name}
                                                        </MenuItem>
                                                    ))}

                                                </Field>
                                            </FormControl>
                                        ))}

                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {i18n.t("Salvar")}
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </>
    );
}