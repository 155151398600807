import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const updates = [
  {
    type: "✅ Adicionado:",
    description:
      "Implementação de ajustes com Inteligência Artificial (AI) no WhatsApp Oficial, melhorando a automação e a precisão no atendimento.",
  },
  {
    type: "✅ Adicionado:",
    description:
      "Correção ortográfica na plataforma foi aplicada, aprimorando a apresentação e clareza dos textos exibidos aos usuários.",
  },
  {
    type: "✅ Adicionado:",
    description:
      "Funcionalidade para inserção de Documentos na opção de arquivo do FlowBuilder, permitindo anexar documentos diretamente ao FlowBuilder.",
  },
  {
    type: "✅ Adicionado:",
    description:
      "Integração com ElevenLabs API, expandindo as funcionalidades do sistema com novas capacidades de processamento de áudio e voz.",
  },
  {
    type: "🚀 Melhorado:",
    description:
      "O fluxo de processamento de tickets foi otimizado e aprimorado, incluindo verificações mais precisas de inatividade para o fechamento automático.",
  },
  {
    type: "🚀 Melhorado:",
    description:
      "Diversas otimizações e melhorias gerais foram realizadas no sistema, aprimorando tanto a performance quanto a experiência do usuário.",
  },
  {
    type: "🚀 Melhorado:",
    description:
      "Revisado o fluxo de fechamento automático de tickets, aprimorando o tratamento de erros.",
  },
  {
    type: "🚀 Melhorado:",
    description:
      "A ordenação das tabelas de Agendamento, Campanhas e Empresas foi ajustada, corrigindo a exibição dos dados.",
  },
  {
    type: "🚀 Melhorado:",
    description:
      "Ajustado problema de conexão que exigia sair e acessar novamente; agora, a conexão é mantida de forma estável.",
  },
  {
    type: "🐞 Corrigido:",
    description:
      "Erro identificado como que causava queda de alguns WhatsApps foi solucionado, garantindo maior estabilidade na comunicação do sistema.",
  },
  {
    type: "🐞 Corrigido:",
    description:
      "Problema de Remarketing Duplicado foi identificado e corrigido para evitar a duplicidade de mensagens.",
  },
  {
    type: "🐞 Corrigido:",
    description:
      "A correção no fluxo de navegação foi aplicada para que, as Conexões da META continuem o fluxo corretamente.",
  },
  {
    type: "🐞 Corrigido:",
    description:
      "Diversos bugs foram resolvidos, incluindo correções na aba de Atendimento, no menu 'Sair' e na conexão com a API oficial do WhatsApp, garantindo uma experiência mais estável.",
  },
];

const UpdatesModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="dashboard-popup-title"
      aria-describedby="dashboard-popup-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "80%",
          overflowY: "auto",
        }}
      >
        <Typography
          id="dashboard-popup-title"
          variant="h5"
          component="h2"
          align="center"
          gutterBottom
          sx={{ color: "#1976d2", fontWeight: "bold" }}
        >
          🆕 Atualizações - Versão 13.80
        </Typography>

        <Typography
          id="dashboard-popup-description"
          sx={{ mt: 2, fontSize: "1rem", lineHeight: 1.6 }}
        >
          <ul>
            {updates.map((update, index) => (
              <div>
                <strong>{update.type}</strong> {update.description}
              </div>
            ))}
          </ul>
        </Typography>

        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
          sx={{ display: "block", margin: "20px auto", fontWeight: "bold" }}
        >
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdatesModal;
