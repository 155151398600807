import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import { startOfHour, parseISO, format, subDays } from "date-fns";

import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const ChartComponent = () => {
  const theme = useTheme();

  const date = useRef(new Date().toISOString());
  const previousDate = useRef(subDays(new Date(), 1).toISOString());
  const { tickets } = useTickets({ date: date.current });
  const { tickets: previousTickets } = useTickets({ date: previousDate.current });

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Dia Atual",
        data: Array(24).fill(0),
      },
      {
        name: "Dia Anterior",
        data: Array(24).fill(0),
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        background: theme.palette.background.paper,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        borderColor: theme.palette.grey[300],
        strokeDashArray: 4,
      },
      xaxis: {
        categories: Array.from({ length: 24 }, (_, i) => `${String(i).padStart(2, "0")}:00`),
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      colors: [theme.palette.primary.main, theme.palette.secondary.main],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.4,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 90, 100],
        },
      },
      tooltip: {
        theme: "dark",
      },
    },
  });

  useEffect(() => {
    let currentDayData = Array(24).fill(0);
    let previousDayData = Array(24).fill(0);

    tickets.forEach((ticket) => {
      const hour = parseInt(format(startOfHour(parseISO(ticket.createdAt)), "HH"));
      currentDayData[hour]++;
    });

    previousTickets.forEach((ticket) => {
      const hour = parseInt(format(startOfHour(parseISO(ticket.createdAt)), "HH"));
      previousDayData[hour]++;
    });

    setChartData((prev) => ({
      ...prev,
      series: [
        { name: "Dia Atual", data: currentDayData },
        { name: "Dia Anterior", data: previousDayData },
      ],
    }));
  }, [tickets, previousTickets]);

  return (
    <Card sx={{ p: 2, boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        <Box display="flex" alignItems="center" gap={1} mb={2}>
          <TrendingUpIcon color="primary" />
          <Typography variant="h6" color="text.primary" fontWeight={600}>
            {`${i18n.t("dashboard.charts.perDay.title")} (${tickets.length})`}
          </Typography>
        </Box>
        <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
      </CardContent>
    </Card>
  );
};

export default ChartComponent;
