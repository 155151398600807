import React, { useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Card, Box, CardContent, Typography, Avatar } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
} from "@material-ui/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CardCounter from "../../components/Integrations/CardCounter";
import api from "../../services/api";
import AsaasIntegrationModal from "../../components/IntegrationModal/asaas";
import EvoIntegrationModal from "../../components/IntegrationModal/evo";
import SGAIntegrationModal from "../../components/IntegrationModal/sga";
import HarmonitIntegrationModal from "../../components/IntegrationModal/harmonit";
import SiprovIntegrationModal from "../../components/IntegrationModal/siprov";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import GroupIcon from "@material-ui/icons/Group";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import asaas from "../../assets/asaas.png";
import sga from "../../assets/sga.png";
import evo from "../../assets/evo.png";
import siprov from "../../assets/siprov.png";
import harmonit from "../../assets/harmonit.png";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 220,
    backgroundColor: "#ffffff", // Cor mais destacada para não se misturar com o fundo
    borderRadius: 16,
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)", // Aumentando a intensidade da sombra
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)", // Mais profundidade ao passar o mouse
    },
  },
  icon: {
    width: 110,
    height: 110,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    color: "#333",
    textAlign: "center", // Centraliza o texto
  },
  loadingText: {
    marginTop: theme.spacing(1),
    color: "#999",
    fontStyle: "italic",
    textAlign: "center", // Centraliza o texto
  },
}));

const Connections = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [IntegrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [IntegrationSGAModalOpen, setIntegrationSGAModalOpen] = useState(false);
  const [IntegrationEVOModalOpen, setIntegrationEVOModalOpen] = useState(false);
  const [IntegrationHarmonitModalOpen, setIntegrationHarmonitModalOpen] =
    useState(false);
  const [IntegrationSiprovModalOpen, setIntegrationSiprovModalOpen] =
    useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenAsaasIntegrationModal = () => {
    setIntegrationModalOpen(true);
  };

  const handleOpenSGAIntegrationModal = () => {
    setIntegrationSGAModalOpen(true);
  };

  const handleOpenEVOIntegrationModal = () => {
    setIntegrationEVOModalOpen(true);
  };

  const handleOpenHarmonitIntegrationModal = () => {
    setIntegrationHarmonitModalOpen(true);
  };

  const handleOpenSiprovIntegrationModal = () => {
    setIntegrationSiprovModalOpen(true);
  };

  const handleCloseIntegrationModal = useCallback(() => {
    setIntegrationModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setIntegrationModalOpen]);

  const handleCloseSGAIntegrationModal = useCallback(() => {
    setIntegrationSGAModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, handleOpenSGAIntegrationModal]);

  const handleCloseEVOIntegrationModal = useCallback(() => {
    setIntegrationEVOModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, handleOpenEVOIntegrationModal]);

  const handleCloseHarmonitIntegrationModal = useCallback(() => {
    setIntegrationHarmonitModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, handleOpenHarmonitIntegrationModal]);

  const handleCloseSiprovIntegrationModal = useCallback(() => {
    setIntegrationSiprovModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, handleOpenSiprovIntegrationModal]);

  const integrations = [
    {
      id: "asaas",
      title: "Asaas",
      icon: asaas,
      onClick: () => {
        handleOpenAsaasIntegrationModal();
      },
    },
    {
      id: "sga",
      title: "SGA",
      icon: sga,
      onClick: () => {
        handleOpenSGAIntegrationModal();
      },
    },
    {
      id: "evo",
      title: "EVO",
      icon: evo,
      onClick: () => {
        handleOpenEVOIntegrationModal();
      },
    },
    {
      id: "harmonit",
      title: "Harmonit",
      icon: harmonit,
      onClick: () => {
        handleOpenHarmonitIntegrationModal();
      },
    },
    {
      id: "siprov",
      title: "Siprov",
      icon: siprov,
      onClick: () => {
        handleOpenSiprovIntegrationModal();
      },
    },
  ];
  return (
    <MainContainer>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
      <QrcodeModal
        open={qrModalOpen}
        whatsAppId={!IntegrationModalOpen && selectedWhatsApp?.id}
      />
      <AsaasIntegrationModal
        open={IntegrationModalOpen}
        onClose={handleCloseIntegrationModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <SGAIntegrationModal
        open={IntegrationSGAModalOpen}
        onClose={handleCloseSGAIntegrationModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <EvoIntegrationModal
        open={IntegrationEVOModalOpen}
        onClose={handleCloseEVOIntegrationModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <HarmonitIntegrationModal
        open={IntegrationHarmonitModalOpen}
        onClose={handleCloseHarmonitIntegrationModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <SiprovIntegrationModal
        open={IntegrationSiprovModalOpen}
        onClose={handleCloseSiprovIntegrationModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />
      <MainHeader>
        <Title>{i18n.t("integrationModal.title.name")}</Title>
      </MainHeader>

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={4} justifyContent="center">
          {integrations.map((integration) => (
            <Grid item xs={12} sm={6} md={4} key={integration.id}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  borderRadius: 2,
                  cursor: "pointer",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: 4,
                  },
                }}
                onClick={integration.onClick}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* Avatar para a logo/ícone */}
                  <Avatar
                    src={integration.icon}
                    alt={integration.title}
                    sx={{
                      width: 40,
                      height: 40,
                      mr: 2,
                      bgcolor: "#fff",
                      border: "1px solid #eee",
                    }}
                  />
                  <CardContent sx={{ p: 0 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {integration.title}
                    </Typography>
                    {loading && (
                      <Typography variant="body2" color="text.secondary">
                        Carregando...
                      </Typography>
                    )}
                  </CardContent>
                </Box>

                {/* Ícone de seta para indicar que é clicável */}
                <ChevronRightIcon sx={{ color: "text.secondary" }} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default Connections;
