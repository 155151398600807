import React, { useState, useEffect } from "react";
import qs from "query-string";
import { cpf, cnpj } from "cpf-cnpj-validator";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import logo from "../../assets/logo.png";

import { i18n } from "../../translate/i18n";
import "./style.css";
import { openApi } from "../../services/api";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";

const styles = {
  avatar: {
    margin: "8px",
    backgroundColor: "#2C3E50", // Cor fixa e elegante para o avatar
  },
  form: {
    width: "100%",
    marginTop: "24px",
  },
  submit: {
    margin: "20px 0px 16px",
    backgroundColor: "#2980B9", // Botão com cor fixa e moderna
    borderRadius: "30px",
    color: "#FFF",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#2471A3", // Efeito hover ajustado
      boxShadow: "none",
    },
  },
  cardHover: {
    border: "1px solid #bdc3c7", // Borda atualizada para um tom elegante
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      transform: "translateY(-5px)",
    },
  },
};

const backgroundStep = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`;
const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png`;
const randomValue = Math.random(); // Generate a random number

const backgroundStepWithRandom = `${backgroundStep}?r=${randomValue}`;
const logoWithRandom = `${logo}?r=${randomValue}`;

const useStyles = makeStyles(() => ({
  customNotchedOutline: {
    borderColor: "#ccc !important", // cor da borda
    borderWidth: "1px !important", // espessura da borda
    borderRadius: "8px !important", // bordas arredondadas
  },
  avatar: {
    margin: "8px",
    backgroundColor: "#2C3E50",
  },
  form: {
    width: "100%",
    marginTop: "24px",
  },
  submit: {
    margin: "20px 0 16px",
    backgroundColor: "#2980B9",
    borderRadius: "30px",
    color: "#FFF",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#2471A3",
      boxShadow: "none",
    },
  },
  cardHover: {
    border: "1px solid #bdc3c7",
    borderRadius: "8px",
    padding: "16px",
    textAlign: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      transform: "translateY(-5px)",
    },
  },
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nome muito curto!")
    .max(50, "Nome muito longo!")
    .required("Nome é obrigatório"),
  password: Yup.string()
    .min(5, "Senha muito curta!")
    .max(50, "Senha muito longa!"),
  phone: Yup.string()
    .min(10, "Telefone muito curto!")
    .max(50, "Telefone muito longo")
    .required("Telefone é obrigatório"),
  email: Yup.string().email("Email inválido").required("Email é obrigatório"),
  cpfcnpj: Yup.string()
    .required("CPF ou CNPJ é obrigatório")
    .test("valid-cpfcnpj", "CPF ou CNPJ inválido", (value) => {
      if (!value) return false;
      return cpf.isValid(value) || cnpj.isValid(value);
    }),
  paymentType: Yup.string().required("Tipo de pagamento é obrigatório"),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [trial, settrial] = useState(3);
  const [allowregister, setallowregister] = useState("enabled");
  const [planError, setPlanError] = useState("");
  let companyId = null;
  const [showImage, setShowImage] = useState(true);
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setShowImage(true);
    img.onerror = () => setShowImage(false);
    img.src = backgroundStepWithRandom;
  }, [backgroundStepWithRandom]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => setShowLogo(true);
    img.onerror = () => setShowLogo(false);
    img.src = logoWithRandom;
  }, [logoWithRandom]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchallowregister();
      await fetchtrial();
    };

    fetchData();
  }, []);

  const fetchtrial = async () => {
    try {
      const responsevvv = await api.get("/public-settings/trial");
      const allowtrialX = responsevvv.data;
      if (allowtrialX !== undefined) {
        settrial(allowtrialX);
      }
    } catch (error) {
      console.error("Erro ao recuperar trial:", error);
    }
  };

  const fetchallowregister = async () => {
    try {
      const responsevv = await api.get("/public-settings/allowregister");
      const allowregisterX = await responsevv.data;
      setallowregister(allowregisterX);
    } catch (error) {
      console.error("Error retrieving allowregister", error);
    }
  };

  if (allowregister === "disabled") {
    history.push("/login");
  }

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = {
    name: "",
    email: "",
    phone: "",
    password: "",
    planId: "",
    cpfcnpj: "",
    paymentType: "",
  };

  const [user] = useState(initialState);
  const dueDate = moment().add(trial, "day").format();

  const handleSignUp = async (values) => {
    if (!values.planId) {
      setPlanError("Por favor, selecione um plano.");
      toast.error(i18n.t("Por favor, selecione um plano."));
      return;
    }
    setPlanError(null);

    Object.assign(values, { recurrence: "MENSAL" });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: "t" });
    Object.assign(values, { campaignsEnabled: true });

    try {
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  const [plans, setPlans] = useState([]);
  const { list: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);
    }
    fetchData();
  }, [listPlans]);

  return (
    <div className="geral-signup">
      <div className="container-signup">
        <div className="paper">
          {showLogo && (
            <img src={logoWithRandom} alt="Logo" className="img-logo-signup" />
          )}
          <h4 className="h4">⚡ Cadastre-se</h4>
          <div>
            <span className="span">
              👋🏻 Comece seu <b>teste GRATUITO</b> de <b>{trial} dias</b> em
              apenas 3 etapas!
              <br />
              <b>Não se preocupe, nós não pedimos dados do seu cartão.</b> 💳
            </span>
          </div>

          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSignUp(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <Form className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <p>Qual o seu nome?</p>
                    <Field
                      as={TextField}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="name"
                      label="Seu Nome"
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9", // fundo dentro do campo
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline, // borda customizada
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p>Seu número de Whatsapp</p>
                    <Field
                      as={TextField}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="phone"
                      label="Telefone com (DDD)"
                      name="phone"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="phone"
                      required
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p>CPF ou CNPJ</p>
                    <Field
                      as={TextField}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="cpfcnpj"
                      label="CPF ou CNPJ"
                      name="cpfcnpj"
                      error={touched.cpfcnpj && Boolean(errors.cpfcnpj)}
                      helperText={touched.cpfcnpj && errors.cpfcnpj}
                      required
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <p>Seus dados de acesso</p>
                    <Field
                      as={TextField}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="email"
                      label={i18n.t("signup.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="email"
                      required
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="password"
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      label={i18n.t("signup.form.password")}
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      required
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                      Escolha seu Plano
                    </Typography>

                    <Grid container spacing={1}>
                      {plans.map((plan, key) =>
                        plan.showSignup ? (
                          <Grid item xs={12} sm={6} md={4} key={key}>
                            <Box
                              onClick={() => {
                                setFieldValue("planId", plan.id);
                                setPlanError(null);
                              }}
                              style={{
                                margin: "12px auto",
                                border: `2px solid ${
                                  values.planId === plan.id ? "#3f51b5" : "#ccc"
                                }`,
                                borderRadius: "12px",
                                padding: "16px",
                                backgroundColor:
                                  values.planId === plan.id
                                    ? "#e8f0fe"
                                    : "#fff",
                                boxShadow:
                                  values.planId === plan.id
                                    ? "0px 6px 12px rgba(63, 81, 181, 0.3)"
                                    : "0px 2px 6px rgba(0, 0, 0, 0.1)",
                                cursor: "pointer",
                                transition: "all 0.3s ease-in-out",
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{ fontWeight: "bold" }}
                                align="center"
                              >
                                {plan.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                align="center"
                                style={{ marginBottom: "8px" }}
                              >
                                <strong>Atendentes:</strong> {plan.users} <br />
                                <strong>WhatsApp:</strong> {plan.connections}
                              </Typography>
                              <Typography
                                variant="body2"
                                align="center"
                                style={{ marginBottom: "8px" }}
                              >
                                <strong>Preço:</strong>{" "}
                                {new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                }).format(plan.value)}
                              </Typography>
                              <Grid container>
                                {[
                                  { label: "Facebook", key: "useFacebook" },
                                  { label: "Instagram", key: "useInstagram" },
                                  {
                                    label: "Agendamentos",
                                    key: "useSchedules",
                                  },
                                  { label: "Campanhas", key: "useCampaigns" },
                                  { label: "Chat Int", key: "useInternalChat" },
                                  {
                                    label: "API Externa",
                                    key: "useExternalApi",
                                  },
                                  { label: "Kanban", key: "useKanban" },
                                  { label: "Automação", key: "useAutomation" },
                                  { label: "Fluxo", key: "useFlow" },
                                  { label: "OpenAI", key: "useOpenAi" },
                                  {
                                    label: "Integrações",
                                    key: "useIntegrations",
                                  },
                                  { label: "Power CRM", key: "usePowerCrm" },
                                  {
                                    label: "Remarketing",
                                    key: "useRemarketing",
                                  },
                                  { label: "Telegram", key: "useTelegram" },
                                  {
                                    label: "API Oficial",
                                    key: "useApiOficial",
                                  },
                                ].map((feature) => (
                                  <React.Fragment key={feature.key}>
                                    <Grid item xs={8}>
                                      <Typography
                                        variant="body2"
                                        align="left"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {feature.label}:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" align="right">
                                        {plan[feature.key] ? (
                                          <span style={{ color: "green" }}>
                                            ✅
                                          </span>
                                        ) : (
                                          <span style={{ color: "red" }}>
                                            ❌
                                          </span>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              </Grid>
                            </Box>
                          </Grid>
                        ) : null
                      )}
                    </Grid>

                    {planError && (
                      <Typography variant="body2" color="error" align="center">
                        Por favor, selecione um plano.
                      </Typography>
                    )}

                    <Field name="planId" type="hidden" />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel htmlFor="paymentType">
                      Tipo de Pagamento
                    </InputLabel>
                    <Field
                      as={Select}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      id="paymentType"
                      label="Tipo de Pagamento"
                      name="paymentType"
                      required
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                        },
                        classes: {
                          notchedOutline: classes.customNotchedOutline,
                        },
                      }}
                    >
                      <MenuItem value="boleto">Boleto</MenuItem>
                      <MenuItem value="pix">PIX</MenuItem>
                    </Field>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  margin="dense"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {i18n.t("signup.buttons.submit")}
                </Button>

                <Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body1"
                      component={RouterLink}
                      to="/login"
                      style={{ color: "#3489ff", fontWeight: 500 }}
                    >
                      {i18n.t("signup.buttons.login")}
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
        <div className="footer">
          <p className="p">Copyright © 2024 </p>
          <p className="p">
            This site is protected by reCAPTCHA Enterprise and the Google{" "}
            <a
              href={"https://policies.google.com/privacy"}
              target={"_blank"}
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href={"https://policies.google.com/terms"}
              target={"_blank"}
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </p>
        </div>
        <Box mt={5}>{/* ... */}</Box>
      </div>
      <div className="container-img-signup">
        {showImage && (
          <div
            className="img-signup"
            style={{
              backgroundImage: `url(${backgroundStepWithRandom})`,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
