import React, { useState, useEffect, useRef } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Stack } from "@mui/material";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    btnWrapper: {
        position: "relative"
    }
}));

const FlowBuilderVariableModal = ({ open, onSave, close, data }) => {
    const classes = useStyles();
    const isMounted = useRef(true);
    const [activeModal, setActiveModal] = useState(false);
    const [variableName, setVariableName] = useState("");

    useEffect(() => {
        if (open === "edit") {
            setVariableName(data?.data?.variable || "");
            setActiveModal(true);
        } else if (open === "create") {
            setVariableName("");
            setActiveModal(true);
        } else {
            setActiveModal(false);
        }
    }, [open, data]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    const handleSaveVariable = () => {
        if (!variableName) {
            return toast.error("Por favor, insira um nome para a variável");
        }

        if (open === "edit") {
            onSave({
                ...data,
                data: { variable: variableName }
            });
        } else {
            onSave({ variable: variableName });
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={activeModal} onClose={handleClose} fullWidth="md" scroll="paper">
                <DialogTitle id="form-dialog-title">Configurar Variável do Fluxo</DialogTitle>
                <Stack>
                    <DialogContent dividers>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="variableName"
                            label="Nome da Variável"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={variableName}
                            onChange={(e) => setVariableName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            Cancelar
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                            onClick={handleSaveVariable}
                            disabled={!variableName}
                        >
                            Salvar
                        </Button>
                    </DialogActions>
                </Stack>
            </Dialog>
        </div>
    );
};

export default FlowBuilderVariableModal;
