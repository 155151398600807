import { ArrowForwardIos, Message, CheckCircle, ContentCopy, Delete } from "@mui/icons-material";
import React, { memo } from "react";
import { useNodeStorage } from "../../../stores/useNodeStorage";
import { Handle } from "react-flow-renderer";

export default memo(({ data, isConnectable, id }) => {
    const storageItems = useNodeStorage();
    return (
        <div
            style={{
                backgroundColor: "#F9FDF9",
                padding: "8px",
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 5px",
                border: '1px solid rgba(58, 186, 56, 0.25)'
            }}
        >
            <Handle
                type="target"
                position="left"
                style={{
                    background: "#0872b9",
                    width: "18px",
                    height: "18px",
                    top: "20px",
                    left: "-12px",
                    cursor: 'pointer'
                }}
                onConnect={params => console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            >
                <ArrowForwardIos
                    sx={{
                        color: "#ffff",
                        width: "10px",
                        height: "10px",
                        marginLeft: "2.9px",
                        marginBottom: "1px",
                        pointerEvents: 'none'
                    }}
                />
            </Handle>
            <div
                style={{
                    color: "#ededed",
                    fontSize: "16px",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px"
                }}
            >
                <CheckCircle
                    sx={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                        marginTop: "4px",
                        color: "#3aba38"
                    }}
                />
                <div style={{ color: "#232323", fontSize: "16px" }}>
                    Finalizar Ticket
                </div>
            </div>
            <div style={{ color: "#232323", fontSize: "12px", width: 180 }}>
                <div
                    style={{
                        backgroundColor: "#F6EEEE",
                        marginBottom: "3px",
                        borderRadius: "5px",
                        padding: "6px",
                        textAlign: "center"
                    }}
                >
                    Este bloco irá finalizar o Ticket
                </div>
            </div>
        </div >
    );
});
