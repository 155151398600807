import React, { useState, useEffect, useContext } from "react";
import { Grid, Paper, Typography, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TodayIcon from "@mui/icons-material/Today";
import SpeedIcon from "@mui/icons-material/Speed";
import PersonIcon from "@mui/icons-material/Person";
import { green, red, yellow, blue, grey } from "@mui/material/colors";

const StatsCard = ({ icon, label, value, change, changeColor }) => {

    return (
        <Paper
            elevation={3}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 16px",
                borderRadius: "12px",
                marginBottom: "8px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        backgroundColor: "#f5f5f5",
                        marginRight: "12px",
                    }}
                >
                    {icon}
                </div>
                <div>
                    <Typography style={{ fontSize: 14, color: "#666" }}>{label}</Typography>

                    {/* Número animado */}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <AnimatedTicketCount ticketCountToday={value} />

                        {/* Seção de variação percentual */}
                        {change !== undefined && (
                            <PercentageCounter percentageChange={change} />
                        )}
                    </div>
                </div>
            </div>
        </Paper>
    );
};

const AnimatedTicketCount = ({ ticketCountToday, loading }) => {
    const [displayedCount, setDisplayedCount] = useState(0);

    useEffect(() => {
        if (loading) {
            setDisplayedCount(0); // Reset to 0 while loading
        } else {
            let start = 0;
            let end = ticketCountToday;
            let step = (end - start) / 100; // Adjust the speed of increment
            let current = start;

            const animate = () => {
                current += step;
                if (current >= end) {
                    current = end; // Stop at the final value
                }
                setDisplayedCount(current);
                if (current < end) {
                    requestAnimationFrame(animate);
                }
            };

            animate();
        }
    }, [ticketCountToday, loading]);

    return (
        <Typography style={{ fontSize: 24, fontWeight: 'bold', color: '#333' }}>
            {loading ? <CircularProgress size={24} /> : <strong>{Math.round(displayedCount)}</strong>}
        </Typography>
    );
};

const PercentageCounter = ({ percentageChange, loading }) => {
    const [displayedPercentage, setDisplayedPercentage] = useState(0);

    useEffect(() => {
        if (loading) {
            setDisplayedPercentage(0); // Reset to 0 while loading
        } else {
            let start = 0;
            let end = percentageChange;
            let step = (end - start) / 100; // Control the speed of animation
            let current = start;

            const animate = () => {
                current += step;
                if (Math.abs(current) >= Math.abs(end)) {
                    current = end; // Stop at the final value
                }
                setDisplayedPercentage(current);
                if (Math.abs(current) < Math.abs(end)) {
                    requestAnimationFrame(animate);
                }
            };

            animate();
        }
    }, [percentageChange, loading]);

    return (
        <Typography style={{ fontSize: 14, color: displayedPercentage >= 0 ? 'green' : 'red', marginLeft: '8px' }}>
            {loading ? '0%' : `${displayedPercentage >= 0 ? '+' : ''}${Math.round(displayedPercentage)}%`}
        </Typography>
    );
};

const Cards = ({ loading, counters }) => {
    const stats = [
        {
            icon: <GroupIcon style={{ color: blue[500] }} />,
            label: "Suporte Pendente",
            value: counters.supportPending,
        },
        {
            icon: <AssignmentIcon style={{ color: blue[300] }} />,
            label: "Suporte Finalizado",
            value: counters.supportFinished,
            change: counters.supportFinishedChange ? parseFloat(counters.supportFinishedChange) : 0,
        },
        {
            icon: <DoneIcon style={{ color: green[500] }} />,
            label: "Suporte em Andamento",
            value: counters.supportHappening
        },
        {
            icon: <TodayIcon style={{ color: yellow[700] }} />,
            label: "Leads",
            value: counters.leadsToday,
            change: counters.leadsChange ? parseFloat(counters.leadsChange) : 0,
        },
        /* {
            icon: <SpeedIcon style={{ color: grey[600] }} />,
            label: "Tempo Médio de Suporte",
            value: counters.avgSupportTime ? `${counters.avgSupportTime} seg` : "0"
        },
        {
            icon: <SpeedIcon style={{ color: grey[600] }} />,
            label: "Tempo Médio de Espera",
            value: counters.avgWaitTime ? `${counters.avgWaitTime} seg` : "0"
        }, */
    ];

    return (
        <Grid item xs={12} md={12}>
            <Paper elevation={3} style={{
                padding: "16px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%"
            }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>Desempenho do Suporte</Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                    stats.map((stat, index) => (
                        <StatsCard key={index} {...stat} />
                    ))
                )}
            </Paper>
        </Grid>
    );
};
export default Cards;
