import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useSettings from "../../hooks/useSettings";
import { toast } from "react-toastify";
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  Switch,
  Collapse,
  IconButton,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import mkauth from "../../assets/mkauth.png";
import ixc from "../../assets/ixc.png";
import asaas from "../../assets/asaas.png";
import sga from "../../assets/sga.png";
import sgp from "../../assets/sgp.png";
import harmonit from "../../assets/harmonit.png";
import siprov from "../../assets/siprov.png";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.options,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24), 0 4px 8px rgba(0, 0, 0, 0.22)",
    },
    // minHeight controlado via estado em cada integração
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  saveButton: {
    marginBottom: theme.spacing(2),
    color: `${theme.palette.primary.contrastText} !important`,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      opacity: 0.9,
    },
  },
}));

export default function Options(props) {
  const { settings } = props;
  const classes = useStyles();
  const { update } = useSettings();

  // --- ESTADOS DE MKAUTH ---
  const [ipmkauthType, setIpMkauthType] = useState("");
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [mkAuthEnabled, setMkAuthEnabled] = useState(false);
  const [mkAuthShowFields, setMkAuthShowFields] = useState(false);

  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] =
    useState(false);
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] =
    useState(false);

  // --- ESTADOS DE IXC ---
  const [ipixcType, setIpIxcType] = useState("");
  const [tokenixcType, setTokenIxcType] = useState("");
  const [ixcEnabled, setIxcEnabled] = useState(false);
  const [ixcShowFields, setIxcShowFields] = useState(false);

  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

  // --- ESTADOS DE ASAAS ---
  const [asaasType, setAsaasType] = useState("");
  const [asaasEnabled, setAsaasEnabled] = useState(false);
  const [asaasShowFields, setAsaasShowFields] = useState(false);

  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  // --- ESTADOS DE SGA ---
  const [sgaType, setSgaType] = useState("");
  const [sgaEnabled, setSgaEnabled] = useState(false);
  const [sgaShowFields, setSgaShowFields] = useState(false);

  const [loadingSgaType, setLoadingSgaType] = useState(false);

  // --- ESTADOS DE SGP ---
  const [ipsgpType, setIpSGPType] = useState("");
  const [tokensgpType, setTokenSGPType] = useState("");
  const [sgpEnabled, setSgpEnabled] = useState(false);
  const [sgpShowFields, setSgpShowFields] = useState(false);

  const [loadingIpSGPType, setLoadingIpSGPType] = useState(false);
  const [loadingTokenSGPType, setLoadingTokenSGPType] = useState(false);

  // --- ESTADOS DE HARMONIT ---
  const [clientIDHarmonitType, setClientIDHarmonitType] = useState("");
  const [secretIdharmonitType, setSecretIdharmonitType] = useState("");
  const [harmonitEnabled, setHarmonitEnabled] = useState(false);
  const [harmonitShowFields, setHarmonitShowFields] = useState(false);

  const [loadingClientIdHarmonitType, setLoadingClientIdHarmonitType] =
    useState(false);
  const [loadingSecretIdHarmonitType, setLoadingSecretIdHarmonitType] =
    useState(false);

  // --- ESTADOS DE SIPROV ---
  const [siprovType, setSiprovType] = useState("");
  const [siprovPassType, setSiprovPassType] = useState("");
  const [siprovIntegrationEnabled, setSiprovIntegrationEnabled] =
    useState(false);
  const [SiprovShowFields, setSiprovShowFields] = useState(false);

  const [loadingSiprovPassType, setLoadingSiprovPassType] = useState(false);

  // Estado para o Card principal de integrações ser minimizável
  const [integrationsExpanded, setIntegrationsExpanded] = useState(false);
  const toggleIntegrations = () => {
    setIntegrationsExpanded(!integrationsExpanded);
  };

  // Carregar dados do backend
  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      // MKAUTH
      const ipmkauthSetting = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthSetting) setIpMkauthType(ipmkauthSetting.value);
      const clientidmkauthSetting = settings.find(
        (s) => s.key === "clientidmkauth"
      );
      if (clientidmkauthSetting)
        setClientIdMkauthType(clientidmkauthSetting.value);
      const clientsecretmkauthSetting = settings.find(
        (s) => s.key === "clientsecretmkauth"
      );
      if (clientsecretmkauthSetting)
        setClientSecrectMkauthType(clientsecretmkauthSetting.value);
      const mkAuthEnabledSetting = settings.find(
        (s) => s.key === "mkAuthEnable"
      );
      if (mkAuthEnabledSetting)
        setMkAuthEnabled(mkAuthEnabledSetting.value === "true");

      // IXC
      const ipixcSetting = settings.find((s) => s.key === "ipixc");
      if (ipixcSetting) setIpIxcType(ipixcSetting.value);
      const tokenixcSetting = settings.find((s) => s.key === "tokenixc");
      if (tokenixcSetting) setTokenIxcType(tokenixcSetting.value);
      const ixcEnabledSetting = settings.find((s) => s.key === "ixcEnable");
      if (ixcEnabledSetting) setIxcEnabled(ixcEnabledSetting.value === "true");

      // ASAAS
      const asaasSetting = settings.find((s) => s.key === "asaas");
      if (asaasSetting) setAsaasType(asaasSetting.value);
      const asaasEnabledSetting = settings.find((s) => s.key === "asaasEnable");
      if (asaasEnabledSetting)
        setAsaasEnabled(asaasEnabledSetting.value === "true");

      // SGA
      const sgaSetting = settings.find((s) => s.key === "sga");
      if (sgaSetting) setSgaType(sgaSetting.value);
      const sgaEnabledSetting = settings.find((s) => s.key === "sgaEnable");
      if (sgaEnabledSetting) setSgaEnabled(sgaEnabledSetting.value === "true");

      // SGP
      const ipsgpSetting = settings.find((s) => s.key === "ipsgp");
      if (ipsgpSetting) setIpSGPType(ipsgpSetting.value);
      const tokensgpSetting = settings.find((s) => s.key === "tokensgp");
      if (tokensgpSetting) setTokenSGPType(tokensgpSetting.value);
      const sgpEnabledSetting = settings.find((s) => s.key === "sgpEnable");
      if (sgpEnabledSetting) setSgpEnabled(sgpEnabledSetting.value === "true");

      // HARMONIT
      const harmonitClientId = settings.find(
        (s) => s.key === "harmonitClientId"
      );
      if (harmonitClientId) setClientIDHarmonitType(harmonitClientId.value);
      const harmonitSecretId = settings.find(
        (s) => s.key === "harmonitSecretId"
      );
      if (harmonitSecretId) setSecretIdharmonitType(harmonitSecretId.value);
      const harmonitEnabledSetting = settings.find(
        (s) => s.key === "harmonitEnable"
      );
      if (harmonitEnabledSetting)
        setHarmonitEnabled(harmonitEnabledSetting.value === "true");

      // SIPROV
      const siprovSetting = settings.find((s) => s.key === "siprov");
      if (siprovSetting) setSiprovType(siprovSetting.value);
      const siprovPassSetting = settings.find((s) => s.key === "siprovPass");
      if (siprovPassSetting) setSiprovPassType(siprovPassSetting.value);
      const siprovEnableSetting = settings.find(
        (s) => s.key === "siprovEnable"
      );
      if (siprovEnableSetting)
        setSiprovIntegrationEnabled(siprovEnableSetting.value === "true");
    }
  }, [settings]);

  // --- Funções de Salvamento (cada integração) ---
  async function handleSaveMkAuth() {
    try {
      setLoadingIpMkauthType(true);
      setLoadingClientIdMkauthType(true);
      setLoadingClientSecrectMkauthType(true);

      await update({ key: "ipmkauth", value: ipmkauthType });
      await update({ key: "clientidmkauth", value: clientidmkauthType });
      await update({
        key: "clientsecretmkauth",
        value: clientsecretmkauthType,
      });
      await update({ key: "mkAuthEnable", value: mkAuthEnabled });

      toast.success("Integração MK-AUTH atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração MK-AUTH.");
    } finally {
      setLoadingIpMkauthType(false);
      setLoadingClientIdMkauthType(false);
      setLoadingClientSecrectMkauthType(false);
    }
  }

  async function handleSaveIxc() {
    try {
      setLoadingIpIxcType(true);
      setLoadingTokenIxcType(true);

      await update({ key: "ipixc", value: ipixcType });
      await update({ key: "tokenixc", value: tokenixcType });
      await update({ key: "ixcEnable", value: ixcEnabled });

      toast.success("Integração IXC atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração IXC.");
    } finally {
      setLoadingIpIxcType(false);
      setLoadingTokenIxcType(false);
    }
  }

  async function handleSaveAsaas() {
    try {
      setLoadingAsaasType(true);

      await update({ key: "asaas", value: asaasType });
      await update({ key: "asaasEnable", value: asaasEnabled });

      toast.success("Integração ASAAS atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração ASAAS.");
    } finally {
      setLoadingAsaasType(false);
    }
  }

  async function handleSaveSga() {
    try {
      setLoadingSgaType(true);

      await update({ key: "sga", value: sgaType });
      await update({ key: "sgaEnable", value: sgaEnabled });

      toast.success("Integração SGA atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração SGA.");
    } finally {
      setLoadingSgaType(false);
    }
  }

  async function handleSaveSgp() {
    try {
      setLoadingIpSGPType(true);
      setLoadingTokenSGPType(true);

      await update({ key: "ipsgp", value: ipsgpType });
      await update({ key: "tokensgp", value: tokensgpType });
      await update({ key: "sgpEnable", value: sgpEnabled });

      toast.success("Integração SGP atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração SGP.");
    } finally {
      setLoadingIpSGPType(false);
      setLoadingTokenSGPType(false);
    }
  }

  async function handleSaveHarmonit() {
    try {
      setLoadingClientIdHarmonitType(true);
      setLoadingSecretIdHarmonitType(true);

      await update({ key: "harmonitClientId", value: clientIDHarmonitType });
      await update({ key: "harmonitSecretId", value: secretIdharmonitType });
      await update({ key: "harmonitEnable", value: harmonitEnabled });

      toast.success("Integração HARMONIT atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração HARMONIT.");
    } finally {
      setLoadingClientIdHarmonitType(false);
      setLoadingSecretIdHarmonitType(false);
    }
  }

  async function handleSaveSiprovIntegration() {
    try {
      setLoadingSiprovPassType(true);

      await update({ key: "siprov", value: siprovType });
      await update({ key: "siprovPass", value: siprovPassType });
      await update({ key: "siprovEnable", value: siprovIntegrationEnabled });

      toast.success("Integração SIPROV atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar a integração SIPROV.");
    } finally {
      setLoadingSiprovPassType(false);
    }
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.sectionTitle}>
        INTEGRAÇÕES
      </Typography>

      {/* Card Principal que envolve os 7 sub-cards */}
      <Card className={classes.paper}>
        <CardHeader
          title="Todas as Integrações"
          action={
            <IconButton onClick={toggleIntegrations}>
              {integrationsExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
        />
        <Collapse in={integrationsExpanded} timeout="auto">
          <CardContent>
            <Grid container spacing={1}>
              {/* ORDEM ALFABÉTICA: ASAAS, HARMONIT, IXC, MK-AUTH, SGA, SGP, SIPROV */}

              {/* ASAAS */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: asaasShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setAsaasShowFields(!asaasShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={asaas}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">ASAAS</Typography>
                    </div>
                    <IconButton>
                      {asaasShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={asaasShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={asaasEnabled}
                          onChange={(e) => setAsaasEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="asaas"
                        name="asaas"
                        margin="dense"
                        type="password"
                        label="Token Asaas"
                        variant="outlined"
                        value={asaasType}
                        onChange={(e) => setAsaasType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingAsaasType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveAsaas}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* HARMONIT */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: harmonitShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setHarmonitShowFields(!harmonitShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={harmonit}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">HARMONIT</Typography>
                    </div>
                    <IconButton>
                      {harmonitShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={harmonitShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={harmonitEnabled}
                          onChange={(e) => setHarmonitEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="clientId"
                        name="clientId"
                        type="text"
                        margin="dense"
                        label="Client ID"
                        variant="outlined"
                        value={clientIDHarmonitType}
                        onChange={(e) =>
                          setClientIDHarmonitType(e.target.value)
                        }
                      />
                      <FormHelperText>
                        {loadingClientIdHarmonitType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="secretId"
                        name="secretId"
                        margin="dense"
                        label="Secret Id"
                        type="password"
                        variant="outlined"
                        value={secretIdharmonitType}
                        onChange={(e) =>
                          setSecretIdharmonitType(e.target.value)
                        }
                      />
                      <FormHelperText>
                        {loadingSecretIdHarmonitType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveHarmonit}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* IXC */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: ixcShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setIxcShowFields(!ixcShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={ixc}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">IXC</Typography>
                    </div>
                    <IconButton>
                      {ixcShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={ixcShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={ixcEnabled}
                          onChange={(e) => setIxcEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="ipixc"
                        name="ipixc"
                        type="text"
                        margin="dense"
                        label="IP do IXC"
                        variant="outlined"
                        value={ipixcType}
                        onChange={(e) => setIpIxcType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingIpIxcType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="tokenixc"
                        name="tokenixc"
                        margin="dense"
                        type="password"
                        label="Token do IXC"
                        variant="outlined"
                        value={tokenixcType}
                        onChange={(e) => setTokenIxcType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingTokenIxcType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveIxc}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* MK-AUTH */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: mkAuthShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setMkAuthShowFields(!mkAuthShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={mkauth}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">MK-AUTH</Typography>
                    </div>
                    <IconButton>
                      {mkAuthShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={mkAuthShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={mkAuthEnabled}
                          onChange={(e) => setMkAuthEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="ipmkauth"
                        name="ipmkauth"
                        type="text"
                        margin="dense"
                        label="Ip Mk-Auth"
                        variant="outlined"
                        value={ipmkauthType}
                        onChange={(e) => setIpMkauthType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingIpMkauthType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="clientidmkauth"
                        name="clientidmkauth"
                        margin="dense"
                        type="text"
                        label="Client Id"
                        variant="outlined"
                        value={clientidmkauthType}
                        onChange={(e) => setClientIdMkauthType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingClientIdMkauthType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="clientsecretmkauth"
                        name="clientsecretmkauth"
                        margin="dense"
                        type="password"
                        label="Client Secret"
                        variant="outlined"
                        value={clientsecretmkauthType}
                        onChange={(e) =>
                          setClientSecrectMkauthType(e.target.value)
                        }
                      />
                      <FormHelperText>
                        {loadingClientSecrectMkauthType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveMkAuth}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* SGA */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: sgaShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setSgaShowFields(!sgaShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={sga}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">SGA</Typography>
                    </div>
                    <IconButton>
                      {sgaShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={sgaShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={sgaEnabled}
                          onChange={(e) => setSgaEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="sga"
                        name="sga"
                        margin="dense"
                        type="password"
                        label="Token SGA"
                        variant="outlined"
                        value={sgaType}
                        onChange={(e) => setSgaType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingSgaType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveSga}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* SGP */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: sgpShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setSgpShowFields(!sgpShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={sgp}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">SGP</Typography>
                    </div>
                    <IconButton>
                      {sgpShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={sgpShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={sgpEnabled}
                          onChange={(e) => setSgpEnabled(e.target.checked)}
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="ipsgp"
                        name="ipsgp"
                        margin="dense"
                        label="Url do SGP"
                        type="text"
                        variant="outlined"
                        value={ipsgpType}
                        onChange={(e) => setIpSGPType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingIpSGPType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="tokensgp"
                        name="tokensgp"
                        margin="dense"
                        label="Token do SGP"
                        type="password"
                        variant="outlined"
                        value={tokensgpType}
                        onChange={(e) => setTokenSGPType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingTokenSGPType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveSgp}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>

              {/* SIPROV */}
              <Grid item xs={12} sm={6} md={4}>
                <Paper
                  className={classes.paper}
                  style={{
                    minHeight: SiprovShowFields ? "280px" : "80px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setSiprovShowFields(!SiprovShowFields)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={siprov}
                        alt="Logo"
                        style={{
                          width: 45,
                          height: 45,
                          borderRadius: "50%",
                        }}
                      />
                      <Typography variant="h6">SIPROV</Typography>
                    </div>
                    <IconButton>
                      {SiprovShowFields ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>

                  <Collapse in={SiprovShowFields}>
                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <Switch
                          checked={siprovIntegrationEnabled}
                          onChange={(e) =>
                            setSiprovIntegrationEnabled(e.target.checked)
                          }
                          color="primary"
                          inputProps={{ "aria-label": "Ativar integração" }}
                        />
                        <Typography variant="body1">
                          Ativar Integração
                        </Typography>
                      </div>
                      <TextField
                        id="siprovuser"
                        name="siprovuser"
                        type="text"
                        margin="dense"
                        label="Usuário Siprov"
                        variant="outlined"
                        value={siprovType}
                        onChange={(e) => setSiprovType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingSiprovPassType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <FormControl
                      className={classes.selectContainer}
                      style={{ marginBottom: "16px" }}
                    >
                      <TextField
                        id="siprovpass"
                        name="siprovpass"
                        margin="dense"
                        label="Senha Siprov"
                        type="password"
                        variant="outlined"
                        value={siprovPassType}
                        onChange={(e) => setSiprovPassType(e.target.value)}
                      />
                      <FormHelperText>
                        {loadingSiprovPassType && "Atualizando..."}
                      </FormHelperText>
                    </FormControl>

                    <Button
                      variant="contained"
                      className={classes.saveButton}
                      onClick={handleSaveSiprovIntegration}
                    >
                      Salvar
                    </Button>
                  </Collapse>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}
