import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    InputLabel,
    Select,
    Switch,
    MenuItem,
    FormControlLabel,
    FormControl,
    Chip,
    Typography,
    Tabs,
    Tab
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import siprov from "../../assets/siprov.png";
//import { AuthContext } from "../../context/Auth/AuthContext";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },

    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    btnClient: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const SessionSchema = Yup.object().shape({
    token: Yup.string()
        .min(2, "Too Short!")
        .max(512, "Too Long!")
        .required("Required"),
});

//const { user } = useContext(AuthContext);
//const { companyId } = user;

const SiprovIntegrationModal = ({ open, onClose, integrationId }) => {
    const classes = useStyles();
    const initialState = {
        hora: "",
        token: "",
        nameToken: "",
        envioAnt: "",
        envioAposVenc: "",
        maxAposVenc: "",
        msgAntVenc: "",
        msgVenc: "",
        msgAposVenc: "",
        msg3AposVenc: "",
        envDiaVenc: "",
        name: "siprov",
        whatsappId: "",

    };
    const [evo, setEvo] = useState(initialState);
    const [id, setId] = useState('')
    const [ListClientModalOpen, setListClientModalOpen] = useState(false);
    const [resp, setResp] = useState({})
    const [whatsapps, setWhatsapps] = useState([]);
    const [companyId, setcompanyId] = useState([]);
    const [tabIndex, setTabIndex] = useState(0); // Movido para o topo do componente

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    /*     useEffect(() => {
            const fetchSession = async () => {
                try {
                    const { data } = await api.get(`/integration/evo`);
                    setResp(data);
                } catch (err) {
                    //toastError(err);
                }
            };
            fetchSession();
        }, []); */

    useEffect(() => {
        const fetchIntegration = async () => {
            try {
                const { data } = await api.get(`/integration`, { params: { name: "siprov" } });
                const { hora, token, envioAnt, nameToken, envioAposVenc, maxAposVenc, msgAntVenc, msgVenc, msgAposVenc, msg3AposVenc, whatsappId, companyId, envDiaVenc } = data[0]
                integrationId = data[0]['id'];
                setId(data[0]['id'])
                setcompanyId(companyId)
                setEvo({ hora, token, envioAnt, envioAposVenc, nameToken, maxAposVenc, msgAntVenc, msgVenc, msgAposVenc, msg3AposVenc, envDiaVenc, whatsappId });
            } catch (err) {
                //toastError(err);
            }
        };
        fetchIntegration();
    }, [integrationId]);


    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get(`/whatsapp`, { params: { companyId, session: 0 } });
                setWhatsapps(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);


    const handleSaveIntegraTion = async (values) => {

        const integrationData = values;
        try {
            if (id) {
                await api.put(`/integration/${id}`, integrationData);
                const { hora, token, envioAnt, envioAposVenc, nameToken, maxAposVenc, envDiaVenc, msgAntVenc, msgVenc, msgAposVenc, whatsappId } = integrationData
                integrationId = integrationData['id'];
                setEvo(integrationData)
                //setEvo(integrationData)
                const fetchIntegration = async () => {
                    try {
                        const { data } = await api.get(`/integration`, { params: { name: "siprov" } });
                        const { hora, token, envioAnt, envioAposVenc, nameToken, envDiaVenc, maxAposVenc, msgAntVenc, msgVenc, msgAposVenc, msg3AposVenc, whatsappId } = data[0]
                        integrationId = data[0]['id'];
                        setId(data[0]['id'])
                        setEvo({ hora, token, envioAnt, envioAposVenc, nameToken, maxAposVenc, envDiaVenc, msgAntVenc, msgVenc, msgAposVenc, msg3AposVenc, whatsappId });
                    } catch (err) {
                        toastError(err);
                    }
                };
                fetchIntegration();

            } else {
                await api.post("/integration", integrationData);
                setEvo(integrationData)
                integrationId = integrationData['id'];
                const fetchIntegration = async () => {
                    try {
                        const { data } = await api.get(`/integration`, { params: { name: "siprov" } });
                        const { hora, token, envioAnt, nameToken, envioAposVenc, maxAposVenc, envDiaVenc, msgAntVenc, msgVenc, msgAposVenc, msg3AposVenc, whatsappId } = data[0]
                        integrationId = data[0]['id'];
                        setId(data[0]['id'])
                        setEvo({ hora, token, envioAnt, nameToken, envioAposVenc, maxAposVenc, msgAntVenc, envDiaVenc, msgVenc, msgAposVenc, msg3AposVenc, whatsappId });
                    } catch (err) {
                        toastError(err);
                    }
                };
                fetchIntegration();
            }
            toast.success(i18n.t("integrationModal.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setEvo(evo);
    };

    const handleRemove = async () => {
        await api.delete(`/integration/${id}`);
        setId(null)
        setEvo(initialState);
        onClose();
        toast.success("Integração removida com sucesso.");
    };


    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    {integrationId
                        ? i18n.t("integrationModal.title.edit")
                        : i18n.t("integrationModal.title.add")}
                </DialogTitle>

                <Formik
                    initialValues={evo}
                    enableReinitialize
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveIntegraTion(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers style={{ overflow: 'hidden' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px' }}>
                                    <img
                                        src={siprov}
                                        alt="Logo Siprov"
                                        style={{ width: '90px', height: '90px', borderRadius: '50%', marginRight: '8px' }}
                                    />
                                    <Typography
                                        style={{ fontWeight: 'bold' }}
                                        variant="subtitle1"
                                    >
                                        {i18n.t("SIPROV")}
                                    </Typography>
                                </div>
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Configuração" />
                                    <Tab label="Mensagens" />
                                </Tabs>

                                {tabIndex === 0 && (
                                    <div>
                                        {/* Configuração */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <div className={classes.multFieldLine}>
                                                    <FormControl
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        className={classes.formControl}
                                                    >
                                                        <InputLabel id="whatsapp-selection-label">
                                                            {i18n.t("campaigns.dialog.form.whatsapp")}
                                                        </InputLabel>
                                                        <Field
                                                            as={Select}
                                                            labelId="whatsapp-selection-label"
                                                            id="whatsappId"
                                                            name="whatsappId"
                                                            error={touched.whatsappId && Boolean(errors.whatsappId)}
                                                        >
                                                            <MenuItem value="">Nenhuma</MenuItem>
                                                            {whatsapps?.map((whatsapp) => (
                                                                <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                                                    {whatsapp.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </FormControl>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                {/* Time Selection */}
                                                <div className={classes.multFieldLine}>
                                                    <Field
                                                        as={TextField}
                                                        label="Horário do Envio"
                                                        type="time"
                                                        name="hora"
                                                        InputLabelProps={{ shrink: true }}
                                                        error={touched.sendAt && Boolean(errors.sendAt)}
                                                        helperText={touched.sendAt && errors.sendAt}
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {/* Credentials Fields */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Usuário")}
                                                    name="nameToken"
                                                    error={touched.nameToken && Boolean(errors.nameToken)}
                                                    helperText={touched.nameToken && errors.nameToken}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    as={TextField}
                                                    label={i18n.t("Senha")}
                                                    type="password"
                                                    name="token"
                                                    error={touched.token && Boolean(errors.token)}
                                                    helperText={touched.token && errors.token}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Additional Settings */}
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                >
                                                    <InputLabel id="envDiaVenc-selection-label">
                                                        {i18n.t("Enviar no dia do vencimento ?")}
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        label="Enviar no dia do vencimento ?"
                                                        id="envDiaVenc"
                                                        name="envDiaVenc"
                                                        error={touched.envDiaVenc && Boolean(errors.envDiaVenc)}
                                                    >
                                                        <MenuItem value="true">Sim</MenuItem>
                                                        <MenuItem value="false">Não</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Field
                                                    as={TextField}
                                                    label="Dias antes do vencimento"
                                                    type="number"
                                                    name="envioAnt"
                                                    inputProps={{ min: 0, max: 29 }}
                                                    error={touched.envioAnt && Boolean(errors.envioAnt)}
                                                    helperText={touched.envioAnt && errors.envioAnt}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Field
                                                    as={TextField}
                                                    label="Após o vencimento enviar a mensagem a cada X dias ?"
                                                    type="envioAposVenc"
                                                    fullWidth
                                                    name="envioAposVenc"
                                                    error={touched.envioAposVenc && Boolean(errors.envioAposVenc)}
                                                    helperText={touched.envioAposVenc && errors.envioAposVenc}
                                                    variant="outlined"
                                                    margin="dense"
                                                    inputProps={{ step: 1, min: 0, max: 29, type: 'number', }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Field
                                                    as={TextField}
                                                    label="Maximo de dias para enviar mensagem ?"
                                                    type="maxAposVenc"
                                                    fullWidth
                                                    name="maxAposVenc"
                                                    error={touched.maxAposVenc && Boolean(errors.maxAposVenc)}
                                                    helperText={touched.maxAposVenc && errors.maxAposVenc}
                                                    variant="outlined"
                                                    margin="dense"
                                                    inputProps={{ step: 1, min: 0, max: 500, type: 'number', }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}

                                {tabIndex === 1 && (
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                border: '1px solid #ccc', // Borda cinza
                                                borderRadius: '8px', // Arredondamento dos cantos
                                                padding: '16px', // Espaçamento interno
                                                marginTop: '16px', // Espaçamento superior
                                                marginBottom: '16px', // Espaçamento inferior
                                                backgroundColor: '#f9f9f9' // Fundo levemente cinza
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    marginBottom: "16px",
                                                    textAlign: "center",
                                                    fontSize: "16px",
                                                    fontWeight: "bold",
                                                    color: "#555"
                                                }}
                                                variant="subtitle1"
                                            >
                                                {i18n.t("Clique em uma variável para copiá-la e colar onde desejar.")}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexWrap: 'wrap',
                                                    gap: '8px' // Espaçamento entre os chips
                                                }}
                                            >
                                                {["name", "branchName", "venc", "valor", "motivo", "chavePix", "codBoleto"].map((variable) => (
                                                    <Chip
                                                        key={variable}
                                                        variant="outlined"
                                                        color="secondary"
                                                        clickable
                                                        label={`*%${variable}%*`}
                                                        onClick={() => navigator.clipboard.writeText(`*%${variable}%*`)}
                                                    />
                                                ))}
                                            </div>
                                        </div>


                                        {[
                                            { label: "Mensagem antes do vencimento", name: "msgAntVenc" },
                                            { label: "Mensagem no vencimento", name: "msgVenc" },
                                            { label: "Mensagem após vencimento", name: "msgAposVenc" },
                                            { label: "Mensagem 3 dias após vencimento", name: "msg3AposVenc" },
                                        ].map(({ label, name }) => (
                                            <div key={name}>
                                                <Field
                                                    as={TextField}
                                                    label={label}
                                                    name={name}
                                                    multiline
                                                    rows={4}
                                                    error={touched[name] && Boolean(errors[name])}
                                                    helperText={touched[name] && errors[name]}
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </DialogContent>

                            <DialogActions>
                                {id && (
                                    <Button
                                        onClick={handleRemove}
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        Remover
                                        {isSubmitting && (
                                            <CircularProgress size={24} className={classes.buttonProgress} />
                                        )}
                                    </Button>
                                )}
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("integrationModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                >
                                    {integrationId
                                        ? i18n.t("integrationModal.buttons.okEdit")
                                        : i18n.t("integrationModal.buttons.okEdit")}
                                    {isSubmitting && (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );


};

export default React.memo(SiprovIntegrationModal);