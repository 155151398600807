import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";


export function StatusFilter({ onFiltered }) {
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {

    }
    fetchData();
  }, []);

  const onChange = (value) => {
    setSelecteds(value);
    const statusArray = value.map((item) => item.status); // Extrai apenas os valores de status
    onFiltered(statusArray); // Envia apenas os status
  };


  const status = [
    { status: 'open', name: `Abertas` },
    { status: 'closed', name: `Fechadas` },
    { status: 'pending', name: `Pendente` },

  ]

  return (
    <Box style={{ padding: "0px 10px 10px" }}>
      <Autocomplete
        multiple
        size="small"
        options={status}
        value={selecteds} // Certifique-se de que selecteds contém objetos do array status
        onChange={(e, v) => onChange(v)}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              style={{
                backgroundColor: option.color || "#eee",
                textShadow: "1px 1px 1px #000",
                color: "white",
              }}
              label={option.name}
              {...getTagProps({ index })}
              size="small"
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Filtro por Status"
          />
        )}
      />

    </Box>
  );
}
