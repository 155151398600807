import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  forwardRef,
  useMemo,
} from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Badge,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

// Ícones do MUI
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BoltIcon from "@mui/icons-material/Bolt";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContactsIcon from "@mui/icons-material/Contacts";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LabelIcon from "@mui/icons-material/Label";
import ChatIcon from "@mui/icons-material/Chat";
import SchoolIcon from "@mui/icons-material/School";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import LanIcon from "@mui/icons-material/Lan";
import CampaignIcon from "@mui/icons-material/Campaign";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CodeIcon from "@mui/icons-material/Code";
import DescriptionIcon from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import api from "../services/api";
import { isArray } from "lodash";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import { SYSTEM_VERSION } from "../utils/version";

// ===================== STYLES =====================

// Largura do menu lateral
const SIDEBAR_WIDTH = 280;

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: SIDEBAR_WIDTH,
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${
    theme.palette.mode === "light" ? "#E5E5E5" : theme.palette.divider
  }`,
  //backgroundColor: theme.palette.background.paper
}));

const UserArea = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  minHeight: 64,
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: 40,
  height: 40,
}));

const TitleAndSettings = styled(Box)({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SidebarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  color: theme.palette.text.primary,
}));

// Removemos o overflow aqui
const MenuContent = styled(Box)(({ theme }) => {
  return {
    flex: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "orange",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "orange",
      borderRadius: "4px",
    },
    scrollbarWidth: "thin",
    scrollbarColor: `${theme.palette.barraSuperior} ${theme.palette.barraSuperior}`,
  };
});

const MaterioSubheader = styled(ListSubheader)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  lineHeight: 1,
  fontSize: "0.75rem",
  fontWeight: 700,
  textTransform: "uppercase",
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
}));

const MaterioMenuItem = styled(ListItemButton)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1, 2),
  // Quando não está selecionado, mantenha bordas retas ou sutilmente arredondadas
  borderRadius: "0 40px 40px 0",
  marginLeft: -10,

  "&.Mui-selected": {
    backgroundColor:
      theme.palette.mode === "light" ? "#E8E8E8" : theme.palette.grey[800],
    color: theme.palette.text.primary,
    // Especificando apenas os cantos direitos
    borderRadius: "0 40px 40px 0",
    marginLeft: -10,
    paddingLeft: theme.spacing(3),
    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
    },
  },
  "&:hover": {
    backgroundColor:
      theme.palette.mode === "light" ? "#E8E8E8" : theme.palette.grey[800],
    color: theme.palette.text.primary,
    // Especificando apenas os cantos direitos
    borderRadius: "0 40px 40px 0",
    marginLeft: -10,
    "& .MuiListItemIcon-root": {
      color: theme.palette.text.primary,
    },
  },
}));

const MaterioListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 36,
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SubMenuList = styled(List)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  borderLeft: `1px solid ${
    theme.palette.mode === "light"
      ? theme.palette.grey[300]
      : theme.palette.grey[700]
  }`,
  paddingLeft: theme.spacing(2),
}));

const SidebarFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
}));

const CustomSubheader = styled(ListSubheader)(({ theme }) => ({
  width: "95%",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.8rem",
  fontWeight: 600,
  textTransform: "uppercase",
  position: "relative",
  color:
    theme.palette.mode === "light"
      ? theme.palette.textPrimary
      : theme.palette.grey[800],
  background:
    theme.palette.mode === "light" ? "#E8E8E8" : theme.palette.grey[800],
  padding: theme.spacing(1, 2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  lineHeight: 1,
}));

// ===================== REDUCER =====================
const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];
    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }
    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);
    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
  return state;
};

// ===================== COMPONENTE =====================
const MainListItems = ({ drawerClose, collapsed }) => {
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [openFilasSubmenu, setOpenFilasSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [showAutomation, setShowUseAutomation] = useState(false);
  const [showFlow, setShowUseFlow] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState("nenhum");
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      try {
        const responsed = await api.get("/public-settings/defaultGateway");
        const responseFinanceiro = responsed?.data;
        setShowFinanceiro(responseFinanceiro);
      } catch (error) {
        console.error("Error retrieving viewregister", error);
      }
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);
      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
      setShowUseAutomation(planConfigs.plan.useAutomation);
      setShowUseFlow(planConfigs.plan.useFlow);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    setInvisible(unreadsCount === 0);
  }, [chats, user.id]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((w) =>
          ["qrcode", "PAIRING", "DISCONNECTED", "TIMEOUT", "OPENING"].includes(
            w.status
          )
        );
        setConnectionWarning(offlineWhats.length > 0);
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const logout = () => {
    drawerClose?.();
    handleLogout();
  };

  const toggleFilasSubmenu = (e) => {
    e.stopPropagation();
    setOpenFilasSubmenu((prev) => !prev);
  };

  const toggleCampaignSubmenu = (e) => {
    e.stopPropagation();
    setOpenCampaignSubmenu((prev) => !prev);
  };

  function ListItemLink({ icon, primary, to, onClick }) {
    const isSelected =
      to === "/" ? location.pathname === "/" : location.pathname.startsWith(to);

    const CustomLink = useMemo(
      () =>
        forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
      [to]
    );

    return (
      <Tooltip title={collapsed ? primary : ""} placement="right">
        <MaterioMenuItem
          selected={isSelected}
          component={CustomLink}
          onClick={() => {
            drawerClose?.();
            if (onClick) onClick();
          }}
        >
          <MaterioListItemIcon>{icon}</MaterioListItemIcon>
          {!collapsed && (
            <ListItemText
              primary={primary}
              primaryTypographyProps={{ fontSize: "0.875rem" }}
            />
          )}
        </MaterioMenuItem>
      </Tooltip>
    );
  }

  return (
    <SidebarContainer>
      {/* TOPO: Avatar + Título */}
      {/* <UserArea>
        <UserAvatar src="" alt="logo" />
        {!collapsed && (
          <TitleAndSettings>
            <SidebarTitle>MATERIO</SidebarTitle>
            <IconButton
              size="small"
              onClick={() => history.push("/settings")}
              sx={{ ml: 1 }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </TitleAndSettings>
        )}
      </UserArea> */}
      <Divider />

      {/* Conteúdo sem scroll */}
      <MenuContent theme={theme}>
        <Can
          role={user.profile}
          perform="drawer-service-items:view"
          no={() => (
            <>
              <CustomSubheader>
                {i18n.t("Atendimento").toUpperCase()}
              </CustomSubheader>
              <List disablePadding>
                <ListItemLink
                  to="/tickets"
                  primary="Atendimentos"
                  icon={<SupportAgentIcon sx={{ color: "#FF4081" }} />}
                />
                <ListItemLink
                  to="/quick-messages"
                  primary="Respostas Rápidas"
                  icon={<BoltIcon sx={{ color: "#FFC107" }} />}
                />
                {showKanban && (
                  <ListItemLink
                    to="/kanban"
                    primary="Kanban"
                    icon={<ViewKanbanIcon sx={{ color: "#4CAF50" }} />}
                  />
                )}
                <ListItemLink
                  to="/todolist"
                  primary="Tarefas"
                  icon={<CheckCircleOutlineIcon sx={{ color: "#E91E63" }} />}
                />
                <ListItemLink
                  to="/contacts"
                  primary="Contatos"
                  icon={<ContactsIcon sx={{ color: "#3F51B5" }} />}
                />
                {showSchedules && (
                  <ListItemLink
                    to="/schedules"
                    primary="Agendamentos"
                    icon={<ScheduleIcon sx={{ color: "#9C27B0" }} />}
                  />
                )}
                <ListItemLink
                  to="/tags"
                  primary="Tags"
                  icon={<LabelIcon sx={{ color: "#009688" }} />}
                />
                {showInternalChat && (
                  <ListItemLink
                    to="/chats"
                    primary="Chat Interno"
                    icon={<ChatIcon sx={{ color: "#FF5722" }} />}
                  />
                )}

                <ListItemLink
                  to="/helps"
                  primary="Treinamento"
                  icon={<SchoolIcon sx={{ color: "#03A9F4" }} />}
                />
              </List>
            </>
          )}
        />

        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              <CustomSubheader>
                {i18n.t("Gerência").toUpperCase()}
              </CustomSubheader>
              <List disablePadding>
                <ListItemLink
                  to="/"
                  primary="Dashboard"
                  icon={<DashboardIcon sx={{ color: "#FF4081" }} />}
                />
              </List>
            </>
          )}
        />

        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              {showAutomation && (
                <>
                  <CustomSubheader>
                    {i18n.t("Automações").toUpperCase()}
                  </CustomSubheader>
                  <List disablePadding>
                    <ListItemLink
                      to="/integrations"
                      primary="Gerenciar"
                      icon={<BuildCircleIcon sx={{ color: "#FFC107" }} />}
                    />
                  </List>
                </>
              )}
              <CustomSubheader>
                {i18n.t("Administração").toUpperCase()}
              </CustomSubheader>
              <List disablePadding>
                <MaterioMenuItem onClick={toggleFilasSubmenu}>
                  <MaterioListItemIcon>
                    <LanIcon sx={{ color: "#4CAF50" }} />
                  </MaterioListItemIcon>
                  {!collapsed && (
                    <ListItemText
                      primary="Filas e Conexões"
                      // Força fonte e tamanho iguais aos dos sub-itens
                      primaryTypographyProps={{
                        fontSize: "0.875rem", // mesmo tamanho
                        fontFamily: '"Poppins", sans-serif', // se estiver usando Poppins
                        fontWeight: 400, // se quiser a mesma espessura
                      }}
                    />
                  )}
                  {openFilasSubmenu ? <ExpandLess /> : <ExpandMore />}
                </MaterioMenuItem>
                <Collapse in={openFilasSubmenu} timeout="auto" unmountOnExit>
                  <SubMenuList disablePadding>
                    <ListItemLink
                      to="/connections"
                      primary="Conexões"
                      icon={
                        <Badge
                          badgeContent={connectionWarning ? "!" : 0}
                          color="error"
                        >
                          <BoltIcon sx={{ color: "#FF4081" }} />
                        </Badge>
                      }
                    />
                    {showFlow && (
                      <ListItemLink
                        to="/flowbuilders"
                        primary="Flowbuilder"
                        icon={<AutoFixHighIcon sx={{ color: "#4690DA" }} />}
                      />
                    )}
                    {showOpenAi && (
                      <ListItemLink
                        to="/prompts"
                        primary="Prompts (OpenAI)"
                        icon={<AllInclusiveIcon sx={{ color: "#03A9F4" }} />}
                      />
                    )}
                    {showIntegrations && (
                      <ListItemLink
                        to="/queue-integration"
                        primary="Integração Filas"
                        icon={<DeviceHubIcon sx={{ color: "#FF5722" }} />}
                      />
                    )}
                    <ListItemLink
                      to="/queues"
                      primary="Filas/Deptos"
                      icon={<EventIcon sx={{ color: "#4CAF50" }} />}
                    />
                  </SubMenuList>
                </Collapse>

                {showCampaigns && (
                  <>
                    <MaterioMenuItem onClick={toggleCampaignSubmenu}>
                      <MaterioListItemIcon>
                        <CampaignIcon sx={{ color: "#4CAF50" }} />
                      </MaterioListItemIcon>
                      {!collapsed && (
                        <ListItemText
                          primary="Campanhas"
                          // Força fonte e tamanho iguais aos dos sub-itens
                          primaryTypographyProps={{
                            fontSize: "0.875rem", // mesmo tamanho
                            fontFamily: '"Poppins", sans-serif', // se estiver usando Poppins
                            fontWeight: 400, // se quiser a mesma espessura
                          }}
                        />
                      )}
                      {openCampaignSubmenu ? <ExpandLess /> : <ExpandMore />}
                    </MaterioMenuItem>

                    <Collapse
                      in={openCampaignSubmenu}
                      timeout="auto"
                      unmountOnExit
                    >
                      <SubMenuList disablePadding>
                        <ListItemLink
                          to="/campaigns"
                          primary="Nova Campanha"
                          icon={<DescriptionIcon sx={{ color: "#E91E63" }} />}
                        />
                        <ListItemLink
                          to="/contact-lists"
                          primary="Listas de Contatos"
                          icon={<GroupsIcon sx={{ color: "#3F51B5" }} />}
                        />
                        <ListItemLink
                          to="/campaigns-config"
                          primary="Configurações"
                          icon={<SettingsIcon sx={{ color: "#009688" }} />}
                        />
                      </SubMenuList>
                    </Collapse>
                  </>
                )}

                {user.super && (
                  <ListItemLink
                    to="/announcements"
                    primary="Avisos"
                    icon={<NotificationsActiveIcon sx={{ color: "#9C27B0" }} />}
                  />
                )}

                <ListItemLink
                  to="/users"
                  primary="Usuários"
                  icon={<GroupsIcon sx={{ color: "#E91E63" }} />}
                />

                <ListItemLink
                  to="/reports"
                  primary="Relatórios"
                  icon={<DescriptionIcon sx={{ color: "#ACAF50" }} />}
                />

                {showExternalApi && (
                  <ListItemLink
                    to="/messages-api"
                    primary="Messages API"
                    icon={<CodeIcon sx={{ color: "#03A9F4" }} />}
                  />
                )}

                {showFinanceiro !== "nenhum" && (
                  <ListItemLink
                    to="/financeiro"
                    primary="Financeiro"
                    icon={<AttachMoneyIcon sx={{ color: "#009688" }} />}
                  />
                )}

                <ListItemLink
                  to="/settings"
                  primary="Configurações"
                  icon={<SettingsIcon sx={{ color: "#FF4081" }} />}
                />
              </List>
            </>
          )}
        />
        <List disablePadding>
          <Tooltip
            title={collapsed ? i18n.t("mainDrawer.appBar.user.logout") : ""}
            placement="right"
          >
            <MaterioMenuItem onClick={logout}>
              <MaterioListItemIcon>
                <RotateLeftIcon sx={{ color: "#FFC107" }} />
              </MaterioListItemIcon>
              {!collapsed && (
                <ListItemText
                  primary={i18n.t("mainDrawer.appBar.user.logout")}
                  primaryTypographyProps={{ fontSize: "0.875rem" }}
                />
              )}
            </MaterioMenuItem>
          </Tooltip>
        </List>
      </MenuContent>

      <SidebarFooter>
        {!collapsed && (
          <Typography variant="caption" color="textSecondary">
            Versão: {SYSTEM_VERSION}
          </Typography>
        )}
      </SidebarFooter>
    </SidebarContainer>
  );
};

export default MainListItems;
