import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  IconButton,
  Paper,
  Button,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  EmojiEmotions as EmojiEmotionsIcon,
  SwapHoriz as SwapHorizIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSettings from "../../hooks/useSettings";
import { Receipt as ReceiptIcon } from "@material-ui/icons";
import mkauth from "../../assets/mkauth.png";
import ixc from "../../assets/ixc.png";
import asaas from "../../assets/asaas.png";
import sga from "../../assets/sga.png";
import sgp from "../../assets/sgp.png";
import harmonit from "../../assets/harmonit.png";
import siprov from "../../assets/siprov.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
    fontFamily: "'Roboto', sans-serif",
  },
  headerTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
    textAlign: "center",
    color: "#212121",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    borderBottom: `2px solid #212121`,
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: "10px",
    marginRight: "10px",
    backgroundColor: theme.palette.options,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24), 0 4px 8px rgba(0, 0, 0, 0.22)",
    },
    // minHeight controlado via estado em cada integração
  },
  card: {
    marginBottom: theme.spacing(1),
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
  },
  cardHeader: {
    //paddingBottom: 0,
    "& .MuiCardHeader-title": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
    alignSelf: "flex-start",
  },
  loadingText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  integrationCard: {
    //padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    //backgroundColor: theme.palette.options,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s ease",
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  saveButton: {
    marginBottom: theme.spacing(2),
    color: `${theme.palette.primary.contrastText} !important`,
    fontWeight: "bold",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      opacity: 0.9,
    },
  },
}));

// Componente auxiliar para cartões colapsáveis (seções gerais)
const CollapsibleCard = ({ title, avatar, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Card
      className={classes.integrationCard}
      onClick={handleExpandClick}
      style={{ cursor: "pointer" }}
    >
      <CardHeader
        title={title}
        avatar={avatar}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={(e) => {
              e.stopPropagation();
              handleExpandClick();
            }}
            aria-expanded={expanded}
            aria-label="expand"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        className={classes.cardHeader}
      />
      <Collapse in={expanded} timeout="auto">
        {children}
      </Collapse>
    </Card>
  );
};

// Componente para o Card principal de Integrações minimizável
const IntegrationsCard = ({
  // ASAAS
  asaasShowFields,
  setAsaasShowFields,
  asaasEnabled,
  setAsaasEnabled,
  asaasType,
  setAsaasType,
  loadingAsaasType,
  // HARMONIT
  harmonitShowFields,
  setHarmonitShowFields,
  harmonitEnabled,
  setHarmonitEnabled,
  clientIDHarmonitType,
  setClientIDHarmonitType,
  secretIdharmonitType,
  setSecretIdharmonitType,
  loadingClientIdHarmonitType,
  loadingSecretIdHarmonitType,
  // IXC
  ixcShowFields,
  setIxcShowFields,
  ixcEnabled,
  setIxcEnabled,
  ipixcType,
  setIpIxcType,
  tokenixcType,
  setTokenIxcType,
  loadingIpIxcType,
  loadingTokenIxcType,
  // MK-AUTH
  mkAuthShowFields,
  setMkAuthShowFields,
  mkAuthEnabled,
  setMkAuthEnabled,
  ipmkauthType,
  setIpMkauthType,
  clientidmkauthType,
  setClientIdMkauthType,
  clientsecretmkauthType,
  setClientSecrectMkauthType,
  loadingIpMkauthType,
  loadingClientIdMkauthType,
  loadingClientSecrectMkauthType,
  // SGA
  sgaShowFields,
  setSgaShowFields,
  sgaEnabled,
  setSgaEnabled,
  sgaType,
  setSgaType,
  loadingSgaType,
  // SGP
  sgpShowFields,
  setSgpShowFields,
  sgpEnabled,
  setSgpEnabled,
  ipsgpType,
  setIpSGPType,
  tokensgpType,
  setTokenSGPType,
  loadingIpSGPType,
  loadingTokenSGPType,
  // SIPROV
  SiprovShowFields,
  setSiprovShowFields,
  siprovIntegrationEnabled,
  setSiprovIntegrationEnabled,
  siprovType,
  setSiprovType,
  siprovPassType,
  setSiprovPassType,
  loadingSiprovPassType,
  // Handlers
  handleSaveAsaas,
  handleSaveHarmonit,
  handleSaveIxc,
  handleSaveMkAuth,
  handleSaveSga,
  handleSaveSgp,
  handleSaveSiprovIntegration,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Card className={classes.integrationCard}>
      <CardHeader
        title="Integração - 2ª Via de Boletos"
        avatar={<ReceiptIcon style={{ color: "#757575" }} />}
        // O clique aqui (no header) alterna o estado do card pai
        onClick={toggleExpanded}
        action={
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleExpanded();
            }}
            aria-label="expand"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        className={classes.cardHeader}
      />
      <Collapse in={expanded} timeout="auto">
        {/* Adiciona stopPropagation para que cliques no conteúdo não fechem o pai */}
        <CardContent onClick={(e) => e.stopPropagation()}>
          <Grid container spacing={1}>
            {/* Aqui ficam os cards filhos (ASAAS, HARMONIT, etc.) */}

            {/* ASAAS */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{
                  minHeight: asaasShowFields ? "280px" : "80px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setAsaasShowFields(!asaasShowFields)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={asaas}
                      alt="Logo"
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: "50%",
                      }}
                    />
                    <Typography variant="h6">ASAAS</Typography>
                  </div>
                  <IconButton>
                    {asaasShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>

                <Collapse in={asaasShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: "16px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        marginBottom: "16px",
                      }}
                    >
                      <Switch
                        checked={asaasEnabled}
                        onChange={(e) => setAsaasEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="asaas"
                      name="asaas"
                      margin="dense"
                      type="password"
                      label="Token Asaas"
                      variant="outlined"
                      value={asaasType}
                      onChange={(e) => setAsaasType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingAsaasType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveAsaas}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* HARMONIT */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: harmonitShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setHarmonitShowFields(!harmonitShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={harmonit}
                      alt="HARMONIT"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">HARMONIT</Typography>
                  </div>
                  <IconButton>
                    {harmonitShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={harmonitShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={harmonitEnabled}
                        onChange={(e) => setHarmonitEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="clientId"
                      name="clientId"
                      margin="dense"
                      type="text"
                      label="Client ID"
                      variant="outlined"
                      fullWidth
                      value={clientIDHarmonitType}
                      onChange={(e) => setClientIDHarmonitType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingClientIdHarmonitType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="secretId"
                      name="secretId"
                      margin="dense"
                      label="Secret Id"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={secretIdharmonitType}
                      onChange={(e) => setSecretIdharmonitType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingSecretIdHarmonitType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveHarmonit}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* IXC */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: ixcShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setIxcShowFields(!ixcShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={ixc}
                      alt="IXC"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">IXC</Typography>
                  </div>
                  <IconButton>
                    {ixcShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={ixcShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={ixcEnabled}
                        onChange={(e) => setIxcEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="ipixc"
                      name="ipixc"
                      margin="dense"
                      type="text"
                      label="IP do IXC"
                      variant="outlined"
                      fullWidth
                      value={ipixcType}
                      onChange={(e) => setIpIxcType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingIpIxcType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="tokenixc"
                      name="tokenixc"
                      margin="dense"
                      type="password"
                      label="Token do IXC"
                      variant="outlined"
                      fullWidth
                      value={tokenixcType}
                      onChange={(e) => setTokenIxcType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingTokenIxcType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveIxc}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* MK-AUTH */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: mkAuthShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setMkAuthShowFields(!mkAuthShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={mkauth}
                      alt="MK-AUTH"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">MK-AUTH</Typography>
                  </div>
                  <IconButton>
                    {mkAuthShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={mkAuthShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={mkAuthEnabled}
                        onChange={(e) => setMkAuthEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="ipmkauth"
                      name="ipmkauth"
                      margin="dense"
                      type="text"
                      label="Ip Mk-Auth"
                      variant="outlined"
                      fullWidth
                      value={ipmkauthType}
                      onChange={(e) => setIpMkauthType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingIpMkauthType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="clientidmkauth"
                      name="clientidmkauth"
                      margin="dense"
                      type="text"
                      label="Client Id"
                      variant="outlined"
                      fullWidth
                      value={clientidmkauthType}
                      onChange={(e) => setClientIdMkauthType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingClientIdMkauthType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="clientsecretmkauth"
                      name="clientsecretmkauth"
                      margin="dense"
                      type="password"
                      label="Client Secret"
                      variant="outlined"
                      fullWidth
                      value={clientsecretmkauthType}
                      onChange={(e) =>
                        setClientSecrectMkauthType(e.target.value)
                      }
                    />
                    <FormHelperText>
                      {loadingClientSecrectMkauthType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveMkAuth}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* SGA */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: sgaShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setSgaShowFields(!sgaShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={sga}
                      alt="SGA"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">SGA</Typography>
                  </div>
                  <IconButton>
                    {sgaShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={sgaShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={sgaEnabled}
                        onChange={(e) => setSgaEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="sga"
                      name="sga"
                      margin="dense"
                      type="password"
                      label="Token SGA"
                      variant="outlined"
                      fullWidth
                      value={sgaType}
                      onChange={(e) => setSgaType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingSgaType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveSga}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* SGP */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: sgpShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setSgpShowFields(!sgpShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={sgp}
                      alt="SGP"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">SGP</Typography>
                  </div>
                  <IconButton>
                    {sgpShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={sgpShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={sgpEnabled}
                        onChange={(e) => setSgpEnabled(e.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="ipsgp"
                      name="ipsgp"
                      margin="dense"
                      label="Url do SGP"
                      type="text"
                      variant="outlined"
                      fullWidth
                      value={ipsgpType}
                      onChange={(e) => setIpSGPType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingIpSGPType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="tokensgp"
                      name="tokensgp"
                      margin="dense"
                      label="Token do SGP"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={tokensgpType}
                      onChange={(e) => setTokenSGPType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingTokenSGPType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveSgp}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>

            {/* SIPROV */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={classes.paper}
                style={{ minHeight: SiprovShowFields ? "280px" : "80px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => setSiprovShowFields(!SiprovShowFields)}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <img
                      src={siprov}
                      alt="SIPROV"
                      style={{ width: 45, height: 45, borderRadius: "50%" }}
                    />
                    <Typography variant="h6">SIPROV</Typography>
                  </div>
                  <IconButton>
                    {SiprovShowFields ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </div>
                <Collapse in={SiprovShowFields}>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        marginBottom: 16,
                      }}
                    >
                      <Switch
                        checked={siprovIntegrationEnabled}
                        onChange={(e) =>
                          setSiprovIntegrationEnabled(e.target.checked)
                        }
                        color="primary"
                        inputProps={{ "aria-label": "Ativar integração" }}
                      />
                      <Typography variant="body1">Ativar Integração</Typography>
                    </div>
                    <TextField
                      id="siprovuser"
                      name="siprovuser"
                      type="text"
                      margin="dense"
                      label="Usuário Siprov"
                      variant="outlined"
                      fullWidth
                      value={siprovType}
                      onChange={(e) => setSiprovType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingSiprovPassType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.selectContainer}
                    style={{ marginBottom: 16 }}
                  >
                    <TextField
                      id="siprovpass"
                      name="siprovpass"
                      margin="dense"
                      label="Senha Siprov"
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={siprovPassType}
                      onChange={(e) => setSiprovPassType(e.target.value)}
                    />
                    <FormHelperText>
                      {loadingSiprovPassType && "Atualizando..."}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.saveButton}
                    onClick={handleSaveSiprovIntegration}
                  >
                    Salvar
                  </Button>
                </Collapse>
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default function Options(props) {
  const { settings, scheduleTypeChanged } = props;
  const classes = useStyles();
  const { update } = useSettings();

  // Estados para configurações gerais
  const [userRating, setUserRating] = useState("disabled");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");
  const [callType, setCallType] = useState("enabled");
  const [SendGreetingAccepted, setSendGreetingAccepted] = useState("enabled");
  const [UserRandom, setUserRandom] = useState("enabled");
  const [sendSignMessage, setSendSignMessage] = useState("enabled");
  const [sendGreetingMessageOneQueues, setSendGreetingMessageOneQueues] =
    useState("enabled");
  const [sendQueuePosition, setSendQueuePosition] = useState("enabled");
  const [SettingsTransfTicket, setSettingsTransfTicket] = useState("enabled");

  // Estados para selects
  const [scheduleType, setScheduleType] = useState("disabled");
  const [chatbotType, setChatbotType] = useState("");

  // Estados de loading para configurações gerais
  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setLoadingCheckMsgIsGroup] = useState(false);
  const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] =
    useState(false);
  const [loadingUserRandom, setLoadingUserRandom] = useState(false);
  const [loadingSendSignMessage, setLoadingSendSignMessage] = useState(false);
  const [
    loadingSendGreetingMessageOneQueues,
    setLoadingSendGreetingMessageOneQueues,
  ] = useState(false);
  const [loadingSendQueuePosition, setLoadingSendQueuePosition] =
    useState(false);
  const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] =
    useState(false);

  // Estados para integrações
  // ASAAS
  const [asaasType, setAsaasType] = useState("");
  const [asaasEnabled, setAsaasEnabled] = useState(false);
  const [asaasShowFields, setAsaasShowFields] = useState(false);
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);
  // HARMONIT
  const [clientIDHarmonitType, setClientIDHarmonitType] = useState("");
  const [secretIdharmonitType, setSecretIdharmonitType] = useState("");
  const [harmonitEnabled, setHarmonitEnabled] = useState(false);
  const [harmonitShowFields, setHarmonitShowFields] = useState(false);
  const [loadingClientIdHarmonitType, setLoadingClientIdHarmonitType] =
    useState(false);
  const [loadingSecretIdHarmonitType, setLoadingSecretIdHarmonitType] =
    useState(false);
  // IXC
  const [ipixcType, setIpIxcType] = useState("");
  const [tokenixcType, setTokenIxcType] = useState("");
  const [ixcEnabled, setIxcEnabled] = useState(false);
  const [ixcShowFields, setIxcShowFields] = useState(false);
  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);
  // MK-AUTH
  const [ipmkauthType, setIpMkauthType] = useState("");
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [mkAuthEnabled, setMkAuthEnabled] = useState(false);
  const [mkAuthShowFields, setMkAuthShowFields] = useState(false);
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] =
    useState(false);
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] =
    useState(false);
  // SGA
  const [sgaType, setSgaType] = useState("");
  const [sgaEnabled, setSgaEnabled] = useState(false);
  const [sgaShowFields, setSgaShowFields] = useState(false);
  const [loadingSgaType, setLoadingSgaType] = useState(false);
  // SGP
  const [ipsgpType, setIpSGPType] = useState("");
  const [tokensgpType, setTokenSGPType] = useState("");
  const [sgpEnabled, setSgpEnabled] = useState(false);
  const [sgpShowFields, setSgpShowFields] = useState(false);
  const [loadingIpSGPType, setLoadingIpSGPType] = useState(false);
  const [loadingTokenSGPType, setLoadingTokenSGPType] = useState(false);
  // SIPROV
  const [siprovType, setSiprovType] = useState("");
  const [siprovPassType, setSiprovPassType] = useState("");
  const [siprovIntegrationEnabled, setSiprovIntegrationEnabled] =
    useState(false);
  const [SiprovShowFields, setSiprovShowFields] = useState(false);
  const [loadingSiprovPassType, setLoadingSiprovPassType] = useState(false);

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      // Configurações Gerais
      const userRatingSetting = settings.find((s) => s.key === "userRating");
      if (userRatingSetting) setUserRating(userRatingSetting.value);
      const scheduleTypeSetting = settings.find(
        (s) => s.key === "scheduleType"
      );
      if (scheduleTypeSetting) setScheduleType(scheduleTypeSetting.value);
      const callTypeSetting = settings.find((s) => s.key === "call");
      if (callTypeSetting) setCallType(callTypeSetting.value);
      const groupSetting = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (groupSetting) setCheckMsgIsGroupType(groupSetting.value);
      const sendGreetingAcceptedSetting = settings.find(
        (s) => s.key === "sendGreetingAccepted"
      );
      if (sendGreetingAcceptedSetting)
        setSendGreetingAccepted(sendGreetingAcceptedSetting.value);
      const settingsTransfTicketSetting = settings.find(
        (s) => s.key === "sendMsgTransfTicket"
      );
      if (settingsTransfTicketSetting)
        setSettingsTransfTicket(settingsTransfTicketSetting.value);
      const userRandomSetting = settings.find((s) => s.key === "userRandom");
      if (userRandomSetting) setUserRandom(userRandomSetting.value);
      const sendSignMessageSetting = settings.find(
        (s) => s.key === "sendSignMessage"
      );
      if (sendSignMessageSetting)
        setSendSignMessage(sendSignMessageSetting.value);
      const sendGreetingMessageOneQueuesSetting = settings.find(
        (s) => s.key === "sendGreetingMessageOneQueues"
      );
      if (sendGreetingMessageOneQueuesSetting)
        setSendGreetingMessageOneQueues(
          sendGreetingMessageOneQueuesSetting.value
        );
      const sendQueuePositionSetting = settings.find(
        (s) => s.key === "sendQueuePosition"
      );
      if (sendQueuePositionSetting)
        setSendQueuePosition(sendQueuePositionSetting.value);
      const chatbotTypeSetting = settings.find((s) => s.key === "chatBotType");
      if (chatbotTypeSetting) setChatbotType(chatbotTypeSetting.value);

      // Integrações
      // MK-AUTH
      const ipmkauthSetting = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthSetting) setIpMkauthType(ipmkauthSetting.value);
      const clientidmkauthSetting = settings.find(
        (s) => s.key === "clientidmkauth"
      );
      if (clientidmkauthSetting)
        setClientIdMkauthType(clientidmkauthSetting.value);
      const clientsecretmkauthSetting = settings.find(
        (s) => s.key === "clientsecretmkauth"
      );
      if (clientsecretmkauthSetting)
        setClientSecrectMkauthType(clientsecretmkauthSetting.value);
      const mkAuthEnabledSetting = settings.find(
        (s) => s.key === "mkAuthEnable"
      );
      if (mkAuthEnabledSetting)
        setMkAuthEnabled(mkAuthEnabledSetting.value === "true");

      // IXC
      const ipixcSetting = settings.find((s) => s.key === "ipixc");
      if (ipixcSetting) setIpIxcType(ipixcSetting.value);
      const tokenixcSetting = settings.find((s) => s.key === "tokenixc");
      if (tokenixcSetting) setTokenIxcType(tokenixcSetting.value);
      const ixcEnabledSetting = settings.find((s) => s.key === "ixcEnable");
      if (ixcEnabledSetting) setIxcEnabled(ixcEnabledSetting.value === "true");

      // ASAAS
      const asaasSetting = settings.find((s) => s.key === "asaas");
      if (asaasSetting) setAsaasType(asaasSetting.value);
      const asaasEnabledSetting = settings.find((s) => s.key === "asaasEnable");
      if (asaasEnabledSetting)
        setAsaasEnabled(asaasEnabledSetting.value === "true");

      // SGA
      const sgaSetting = settings.find((s) => s.key === "sga");
      if (sgaSetting) setSgaType(sgaSetting.value);
      const sgaEnabledSetting = settings.find((s) => s.key === "sgaEnable");
      if (sgaEnabledSetting) setSgaEnabled(sgaEnabledSetting.value === "true");

      // SGP
      const ipsgpSetting = settings.find((s) => s.key === "ipsgp");
      if (ipsgpSetting) setIpSGPType(ipsgpSetting.value);
      const tokensgpSetting = settings.find((s) => s.key === "tokensgp");
      if (tokensgpSetting) setTokenSGPType(tokensgpSetting.value);
      const sgpEnabledSetting = settings.find((s) => s.key === "sgpEnable");
      if (sgpEnabledSetting) setSgpEnabled(sgpEnabledSetting.value === "true");

      // HARMONIT
      const harmonitClientId = settings.find(
        (s) => s.key === "harmonitClientId"
      );
      if (harmonitClientId) setClientIDHarmonitType(harmonitClientId.value);
      const harmonitSecretId = settings.find(
        (s) => s.key === "harmonitSecretId"
      );
      if (harmonitSecretId) setSecretIdharmonitType(harmonitSecretId.value);
      const harmonitEnabledSetting = settings.find(
        (s) => s.key === "harmonitEnable"
      );
      if (harmonitEnabledSetting)
        setHarmonitEnabled(harmonitEnabledSetting.value === "true");

      // SIPROV
      const siprovSetting = settings.find((s) => s.key === "siprov");
      if (siprovSetting) setSiprovType(siprovSetting.value);
      const siprovPassSetting = settings.find((s) => s.key === "siprovPass");
      if (siprovPassSetting) setSiprovPassType(siprovPassSetting.value);
      const siprovEnableSetting = settings.find(
        (s) => s.key === "siprovEnable"
      );
      if (siprovEnableSetting)
        setSiprovIntegrationEnabled(siprovEnableSetting.value === "true");
    }
  }, [settings]);

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({ key: "userRating", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserRating(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setLoadingCheckMsgIsGroup(true);
    await update({ key: "CheckMsgIsGroup", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCheckMsgIsGroup(false);
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({ key: "call", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCallType(false);
  }

  async function handleSendGreetingAccepted(value) {
    setSendGreetingAccepted(value);
    setLoadingSendGreetingAccepted(true);
    await update({ key: "sendGreetingAccepted", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingSendGreetingAccepted(false);
  }

  async function handleUserRandom(value) {
    setUserRandom(value);
    setLoadingUserRandom(true);
    await update({ key: "userRandom", value });
    setLoadingUserRandom(false);
  }

  async function handleSendSignMessage(value) {
    setSendSignMessage(value);
    setLoadingSendSignMessage(true);
    await update({ key: "sendSignMessage", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingSendSignMessage(false);
  }

  async function handleSendGreetingMessageOneQueues(value) {
    setSendGreetingMessageOneQueues(value);
    setLoadingSendGreetingMessageOneQueues(true);
    await update({ key: "sendGreetingMessageOneQueues", value });
    setLoadingSendGreetingMessageOneQueues(false);
  }

  async function handleSendQueuePosition(value) {
    setSendQueuePosition(value);
    setLoadingSendQueuePosition(true);
    await update({ key: "sendQueuePosition", value });
    setLoadingSendQueuePosition(false);
  }

  async function handleSettingsTransfTicket(value) {
    setSettingsTransfTicket(value);
    setLoadingSettingsTransfTicket(true);
    await update({ key: "sendMsgTransfTicket", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingSettingsTransfTicket(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({ key: "scheduleType", value });
    toast.success("Operação atualizada com sucesso.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({ key: "chatBotType", value });
    toast.success("Operação atualizada com sucesso.");
    setLoadingChatbotType(false);
  }

  // Funções de salvamento das integrações
  async function handleSaveAsaas() {
    try {
      setLoadingAsaasType(true);
      await update({ key: "asaas", value: asaasType });
      await update({ key: "asaasEnable", value: asaasEnabled });
      toast.success("Integração ASAAS atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração ASAAS.");
    } finally {
      setLoadingAsaasType(false);
    }
  }

  async function handleSaveHarmonit() {
    try {
      setLoadingClientIdHarmonitType(true);
      setLoadingSecretIdHarmonitType(true);
      await update({ key: "harmonitClientId", value: clientIDHarmonitType });
      await update({ key: "harmonitSecretId", value: secretIdharmonitType });
      await update({ key: "harmonitEnable", value: harmonitEnabled });
      toast.success("Integração HARMONIT atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração HARMONIT.");
    } finally {
      setLoadingClientIdHarmonitType(false);
      setLoadingSecretIdHarmonitType(false);
    }
  }

  async function handleSaveIxc() {
    try {
      setLoadingIpIxcType(true);
      setLoadingTokenIxcType(true);
      await update({ key: "ipixc", value: ipixcType });
      await update({ key: "tokenixc", value: tokenixcType });
      await update({ key: "ixcEnable", value: ixcEnabled });
      toast.success("Integração IXC atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração IXC.");
    } finally {
      setLoadingIpIxcType(false);
      setLoadingTokenIxcType(false);
    }
  }

  async function handleSaveMkAuth() {
    try {
      setLoadingIpMkauthType(true);
      setLoadingClientIdMkauthType(true);
      setLoadingClientSecrectMkauthType(true);
      await update({ key: "ipmkauth", value: ipmkauthType });
      await update({ key: "clientidmkauth", value: clientidmkauthType });
      await update({
        key: "clientsecretmkauth",
        value: clientsecretmkauthType,
      });
      await update({ key: "mkAuthEnable", value: mkAuthEnabled });
      toast.success("Integração MK-AUTH atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração MK-AUTH.");
    } finally {
      setLoadingIpMkauthType(false);
      setLoadingClientIdMkauthType(false);
      setLoadingClientSecrectMkauthType(false);
    }
  }

  async function handleSaveSga() {
    try {
      setLoadingSgaType(true);
      await update({ key: "sga", value: sgaType });
      await update({ key: "sgaEnable", value: sgaEnabled });
      toast.success("Integração SGA atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração SGA.");
    } finally {
      setLoadingSgaType(false);
    }
  }

  async function handleSaveSgp() {
    try {
      setLoadingIpSGPType(true);
      setLoadingTokenSGPType(true);
      await update({ key: "ipsgp", value: ipsgpType });
      await update({ key: "tokensgp", value: tokensgpType });
      await update({ key: "sgpEnable", value: sgpEnabled });
      toast.success("Integração SGP atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar integração SGP.");
    } finally {
      setLoadingIpSGPType(false);
      setLoadingTokenSGPType(false);
    }
  }

  async function handleSaveSiprovIntegration() {
    try {
      setLoadingSiprovPassType(true);
      await update({ key: "siprov", value: siprovType });
      await update({ key: "siprovPass", value: siprovPassType });
      await update({ key: "siprovEnable", value: siprovIntegrationEnabled });
      toast.success("Integração SIPROV atualizada com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar a integração SIPROV.");
    } finally {
      setLoadingSiprovPassType(false);
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h4" className={classes.headerTitle}>
          Configurações
        </Typography>
        <Grid container spacing={3}>
          {/* Card de Configurações Gerais */}
          <Grid item xs={12}>
            <CollapsibleCard
              title="Configurações Gerais"
              avatar={<SettingsIcon style={{ color: "#757575" }} />}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={userRating === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleChangeUserRating(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Avaliações"
                    />
                    {loadingUserRating && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      style={{ marginBottom: 16 }}
                    >
                      <InputLabel id="schedule-type-label">
                        Gerenciamento de Expediente
                      </InputLabel>
                      <Select
                        labelId="schedule-type-label"
                        label="Gerenciamento de Expediente"
                        value={scheduleType}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleScheduleType(e.target.value)}
                      >
                        <MenuItem value={"disabled"}>Desabilitado</MenuItem>
                        <MenuItem value={"queue"}>Fila</MenuItem>
                        <MenuItem value={"company"}>Empresa</MenuItem>
                      </Select>
                      {loadingScheduleType && (
                        <Typography
                          variant="caption"
                          className={classes.loadingText}
                        >
                          Atualizando...
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={CheckMsgIsGroup === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleGroupType(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Ignorar Mensagens de Grupos"
                    />
                    {loadingCheckMsgIsGroup && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={callType === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleCallType(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Aceitar Chamada"
                    />
                    {loadingCallType && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CollapsibleCard>
          </Grid>

          {/* Card de Saudação e Operador */}
          <Grid item xs={12}>
            <CollapsibleCard
              title="Saudação e Operador"
              avatar={<EmojiEmotionsIcon style={{ color: "#757575" }} />}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={SendGreetingAccepted === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleSendGreetingAccepted(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Enviar saudação ao aceitar o ticket"
                    />
                    {loadingSendGreetingAccepted && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sendSignMessage === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleSendSignMessage(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Permite atendente escolher ENVIAR Assinatura"
                    />
                    {loadingSendSignMessage && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sendGreetingMessageOneQueues === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleSendGreetingMessageOneQueues(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Enviar saudação quando houver somente 1 setor"
                    />
                    {loadingSendGreetingMessageOneQueues && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={sendQueuePosition === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleSendQueuePosition(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Enviar mensagem com a posição na fila"
                    />
                    {loadingSendQueuePosition && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CollapsibleCard>
          </Grid>

          {/* Card de Transferência de Fila */}
          <Grid item xs={12}>
            <CollapsibleCard
              title="Transferência de Fila"
              avatar={<SwapHorizIcon style={{ color: "#757575" }} />}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={SettingsTransfTicket === "enabled"}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleSettingsTransfTicket(
                              e.target.checked ? "enabled" : "disabled"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Enviar mensagem de transferência de Fila/agente"
                    />
                    {loadingSettingsTransfTicket && (
                      <Typography
                        variant="caption"
                        className={classes.loadingText}
                      >
                        Atualizando...
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CollapsibleCard>
          </Grid>

          {/* Card Principal de Integrações Minimizable */}
          <Grid item xs={12}>
            <IntegrationsCard
              // ASAAS
              asaasShowFields={asaasShowFields}
              setAsaasShowFields={setAsaasShowFields}
              asaasEnabled={asaasEnabled}
              setAsaasEnabled={setAsaasEnabled}
              asaasType={asaasType}
              setAsaasType={setAsaasType}
              loadingAsaasType={loadingAsaasType}
              // HARMONIT
              harmonitShowFields={harmonitShowFields}
              setHarmonitShowFields={setHarmonitShowFields}
              harmonitEnabled={harmonitEnabled}
              setHarmonitEnabled={setHarmonitEnabled}
              clientIDHarmonitType={clientIDHarmonitType}
              setClientIDHarmonitType={setClientIDHarmonitType}
              secretIdharmonitType={secretIdharmonitType}
              setSecretIdharmonitType={setSecretIdharmonitType}
              loadingClientIdHarmonitType={loadingClientIdHarmonitType}
              loadingSecretIdHarmonitType={loadingSecretIdHarmonitType}
              // IXC
              ixcShowFields={ixcShowFields}
              setIxcShowFields={setIxcShowFields}
              ixcEnabled={ixcEnabled}
              setIxcEnabled={setIxcEnabled}
              ipixcType={ipixcType}
              setIpIxcType={setIpIxcType}
              tokenixcType={tokenixcType}
              setTokenIxcType={setTokenIxcType}
              loadingIpIxcType={loadingIpIxcType}
              loadingTokenIxcType={loadingTokenIxcType}
              // MK-AUTH
              mkAuthShowFields={mkAuthShowFields}
              setMkAuthShowFields={setMkAuthShowFields}
              mkAuthEnabled={mkAuthEnabled}
              setMkAuthEnabled={setMkAuthEnabled}
              ipmkauthType={ipmkauthType}
              setIpMkauthType={setIpMkauthType}
              clientidmkauthType={clientidmkauthType}
              setClientIdMkauthType={setClientIdMkauthType}
              clientsecretmkauthType={clientsecretmkauthType}
              setClientSecrectMkauthType={setClientSecrectMkauthType}
              loadingIpMkauthType={loadingIpMkauthType}
              loadingClientIdMkauthType={loadingClientIdMkauthType}
              loadingClientSecrectMkauthType={loadingClientSecrectMkauthType}
              // SGA
              sgaShowFields={sgaShowFields}
              setSgaShowFields={setSgaShowFields}
              sgaEnabled={sgaEnabled}
              setSgaEnabled={setSgaEnabled}
              sgaType={sgaType}
              setSgaType={setSgaType}
              loadingSgaType={loadingSgaType}
              // SGP
              sgpShowFields={sgpShowFields}
              setSgpShowFields={setSgpShowFields}
              sgpEnabled={sgpEnabled}
              setSgpEnabled={setSgpEnabled}
              ipsgpType={ipsgpType}
              setIpSGPType={setIpSGPType}
              tokensgpType={tokensgpType}
              setTokenSGPType={setTokenSGPType}
              loadingIpSGPType={loadingIpSGPType}
              loadingTokenSGPType={loadingTokenSGPType}
              // SIPROV
              SiprovShowFields={SiprovShowFields}
              setSiprovShowFields={setSiprovShowFields}
              siprovIntegrationEnabled={siprovIntegrationEnabled}
              setSiprovIntegrationEnabled={setSiprovIntegrationEnabled}
              siprovType={siprovType}
              setSiprovType={setSiprovType}
              siprovPassType={siprovPassType}
              setSiprovPassType={setSiprovPassType}
              loadingSiprovPassType={loadingSiprovPassType}
              // Handlers de salvamento
              handleSaveAsaas={handleSaveAsaas}
              handleSaveHarmonit={handleSaveHarmonit}
              handleSaveIxc={handleSaveIxc}
              handleSaveMkAuth={handleSaveMkAuth}
              handleSaveSga={handleSaveSga}
              handleSaveSgp={handleSaveSgp}
              handleSaveSiprovIntegration={handleSaveSiprovIntegration}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
