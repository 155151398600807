import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  TextField,
  Switch,
  FormControlLabel,
  IconButton,
  Button,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import {
  Image as ImageIcon,
  ImageSearch,
  Colorize,
  Settings,
  Payment,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import useSettings from "../../hooks/useSettings";
import ColorBoxModal from "../ColorBoxModal/index.js";
import ColorModeContext from "../../layout/themeContext";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: "#f9f9f9",
    minHeight: "100vh",
    fontFamily: "'Roboto', sans-serif",
  },
  headerTitle: {
    marginBottom: theme.spacing(3),
    fontWeight: 700,
    textAlign: "center",
    color: "#212121",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    borderBottom: `2px solid #212121`,
    paddingBottom: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
  },
  cardHeader: {
    //paddingBottom: 0,
    "& .MuiCardHeader-title": {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  integrationCard: {
    //padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    //backgroundColor: theme.palette.options,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: "all 0.3s ease",
  },
  imagePreviewContainer: {
    border: "1px solid #ddd",
    borderRadius: 8,
    padding: theme.spacing(2),
    textAlign: "center",
    minHeight: 180,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  imagePreview: {
    maxWidth: "100%",
    maxHeight: 160,
    objectFit: "contain",
    borderRadius: 4,
  },
  noImage: {
    width: 160,
    height: 160,
    border: "2px dashed #ccc",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#aaa",
  },
  fileInput: {
    display: "none",
  },
  fileInputLabel: {
    display: "inline-block",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  swatchContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    cursor: "pointer",
  },
  swatchCircle: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #ccc",
    marginLeft: theme.spacing(2),
  },
  configSection: {
    padding: theme.spacing(3),
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    marginTop: theme.spacing(4),
  },
  columnContainer: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
  // Estilos para o botão de expandir/contrair
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  // Estilos para o formulário de upload em uma única linha
  uploadRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  uploadItem: {
    flex: 1,
  },
}));

// Componente CollapsibleCard – todos iniciam minimizados (expanded = false)
const CollapsibleCard = ({ title, avatar, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Card
      className={classes.integrationCard}
      onClick={handleExpandClick}
      style={{ cursor: "pointer" }}
    >
      <CardHeader
        title={title}
        avatar={avatar}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={(e) => {
              e.stopPropagation();
              handleExpandClick();
            }}
            aria-expanded={expanded}
            aria-label="expand"
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        className={classes.cardHeader}
      />
      <Collapse in={expanded} timeout="auto">
        {children}
      </Collapse>
    </Card>
  );
};

// Componente para exibir a imagem com fallback caso não seja carregada
const ImagePreview = ({ src, alt, classes }) => {
  const [imgError, setImgError] = useState(false);
  return !imgError && src ? (
    <img
      src={src}
      alt={alt}
      className={classes.imagePreview}
      onError={() => setImgError(true)}
    />
  ) : (
    <div className={classes.noImage}>
      <ImageIcon style={{ fontSize: 48 }} />
    </div>
  );
};

const Uploader = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { settings } = props;
  const { update } = useSettings();
  const { colorMode } = useContext(ColorModeContext);

  // Estados de Upload
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [imageRefreshKey, setImageRefreshKey] = useState(Date.now());

  // Estados White Label
  const [color, setColor] = useState("");
  const [primaryColorLightModalOpen, setPrimaryColorLightModalOpen] =
    useState(false);
  const [titlePage, setTitlePage] = useState("");

  // Outros estados de configurações
  const [viewregister, setViewregister] = useState("disabled");
  const [trial, setTrial] = useState("3");
  const [blockuser, setBlockUser] = useState("3");
  const [numeroSuporte, setNumeroSuporte] = useState("");

  // Estados Gateway
  const [defaultGateway, setDefaultGateway] = useState("");
  const [enableAsaasGateway, setEnableAsaasGateway] = useState(false);
  const [asaasToken, setAsaasToken] = useState("");

  // Estados de loading
  const [loadingTitlePage, setLoadingTitlePage] = useState(false);
  const [loadingTrial, setLoadingTrial] = useState(false);
  const [loadingBlock, setLoadingBlock] = useState(false);
  const [loadingViewregister, setLoadingViewregister] = useState(false);
  const [loadingAsaasToken, setLoadingAsaasToken] = useState(false);
  const [loadingNumeroSuporte, setLoadingNumeroSuporte] = useState(false);
  const [loadingDefaultGateway, setLoadingDefaultGateway] = useState(false);
  const [loadingColor, setLoadingColor] = useState(false);

  // Carregar settings do backend
  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const primaryColorLight = settings.find(
        (s) => s.key === "primaryColorLight"
      );
      if (primaryColorLight) setColor(primaryColorLight.value);
      const titlePageSetting = settings.find((s) => s.key === "titlePage");
      if (titlePageSetting) setTitlePage(titlePageSetting.value);
      const viewReg = settings.find((s) => s.key === "viewregister");
      if (viewReg) setViewregister(viewReg.value);
      const trialSetting = settings.find((s) => s.key === "trial");
      if (trialSetting) setTrial(trialSetting.value);
      const blockSetting = settings.find((s) => s.key === "blockuser");
      if (blockSetting) setBlockUser(blockSetting.value);
      const numeroSup = settings.find((s) => s.key === "numeroSuporte");
      if (numeroSup) setNumeroSuporte(numeroSup.value);
      const defaultG = settings.find((s) => s.key === "defaultGateway");
      if (defaultG) setDefaultGateway(defaultG.value);
      const enableAsaas = settings.find((s) => s.key === "enableAsaasGateway");
      if (enableAsaas) setEnableAsaasGateway(enableAsaas.value === "true");
      const asaasTok = settings.find((s) => s.key === "gatewayAsaasToken");
      if (asaasTok) setAsaasToken(asaasTok.value);
    }
  }, [settings]);

  // HANDLERS DE ATUALIZAÇÃO NO BACKEND
  async function handleSaveSetting(key, value) {
    await update({ key, value });
    toast.success("Operação atualizada com sucesso.");
  }

  // HANDLERS DE UPLOAD
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/png", "image/x-icon", "image/svg+xml"];
    if (selectedFile && allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      setSelectedFileName(selectedFile.name);
    } else {
      setFile(null);
      setSelectedFileName("");
      toast.error("Use somente arquivos em formato PNG, ICO ou SVG!");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.warn("Escolha um arquivo!");
      return;
    }
    if (!selectedOption) {
      toast.warn("Escolha um destino!");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const { data } = await api.post(
        `/settings/media-upload?ref=${selectedOption}`,
        formData
      );
      if (data.mensagem === "Arquivo Anexado") {
        setUploaded(true);
        toast.success("Arquivo enviado com sucesso!");
        setImageRefreshKey(Date.now());
      }
    } catch (error) {
      toastError(error);
    }
  };

  // HANDLERS WHITE LABEL
  const handleOpenColorModal = () => {
    setPrimaryColorLightModalOpen(true);
  };

  const handleCloseColorModal = () => {
    setPrimaryColorLightModalOpen(false);
  };

  const handleChangeColor = (selectedColor) => {
    const newColor = `#${selectedColor.hex}`;
    setColor(newColor);
    handleSaveSetting("primaryColorLight", newColor);
    colorMode.setPrimaryColorLight(newColor);
  };

  const handleTitlePageChange = (e) => {
    setTitlePage(e.target.value);
  };

  const handleTitlePageSave = async () => {
    try {
      await update({ key: "titlePage", value: titlePage });
      toast.success("Título da página atualizado com sucesso.");
      setImageRefreshKey(Date.now());
    } catch (err) {
      toastError(err);
    }
  };

  // HANDLERS CONFIGURAÇÕES BÁSICAS
  const handleViewregisterChange = async (e) => {
    const value = e.target.value;
    setViewregister(value);
    await handleSaveSetting("viewregister", value);
  };

  const handleTrialChange = async (e) => {
    const value = e.target.value;
    setTrial(value);
    await handleSaveSetting("trial", value);
  };

  const handleBlockChange = async (e) => {
    const value = e.target.value;
    setBlockUser(value);
    await handleSaveSetting("blockuser", value);
  };

  const handleNumeroSuporteChange = async (e) => {
    const value = e.target.value;
    setNumeroSuporte(value);
    await handleSaveSetting("numeroSuporte", value);
  };

  // HANDLERS GATEWAY
  const handleDefaultGatewayChange = async (e) => {
    const value = e.target.value;
    setDefaultGateway(value);
    await handleSaveSetting("defaultGateway", value);
  };

  const handleEnableAsaasChange = async (e) => {
    const checked = e.target.checked;
    setEnableAsaasGateway(checked);
    await handleSaveSetting("enableAsaasGateway", checked);
  };

  const handleAsaasTokenChange = (e) => {
    setAsaasToken(e.target.value);
  };

  const handleAsaasSave = async () => {
    try {
      await handleSaveSetting("gatewayAsaasToken", asaasToken);
      toast.success("Configuração Asaas atualizada.");
    } catch (err) {
      toastError(err);
    }
  };

  // COMPONENTE: Renderização do card de imagem com fallback
  const renderPreviewCard = (label, imageUrl) => {
    return (
      <Grid item xs={12} sm={4}>
        <Card className={classes.integrationCard}>
          <CardHeader
            title={label}
            className={classes.cardHeader}
            avatar={<ImageSearch style={{ color: "#757575" }} />}
          />
          <CardContent>
            <div className={classes.imagePreviewContainer}>
              <ImagePreview src={imageUrl} alt={label} classes={classes} />
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  // COMPONENTE: Imagem com fallback
  const ImagePreview = ({ src, alt, classes }) => {
    const [imgError, setImgError] = useState(false);
    return !imgError && src ? (
      <img
        src={src}
        alt={alt}
        className={classes.imagePreview}
        onError={() => setImgError(true)}
      />
    ) : (
      <div className={classes.noImage}>
        <ImageIcon style={{ fontSize: 48 }} />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.headerTitle}>
        Configurações White Label
      </Typography>

      {/* Pré-visualização de Imagens */}
      <Grid container spacing={3}>
        {renderPreviewCard(
          "Tela de Registro",
          `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png?v=${imageRefreshKey}`
        )}
        {renderPreviewCard(
          "Logotipo Externo",
          `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/login.png?v=${imageRefreshKey}`
        )}
        {renderPreviewCard(
          "Logotipo Interno",
          `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/interno.png?v=${imageRefreshKey}`
        )}
      </Grid>

      {/* Card de Upload - tudo na mesma linha */}
      <CollapsibleCard
        title="Upload de Arquivo"
        avatar={<ImageSearch style={{ color: "#757575" }} />}
      >
        <Divider className={classes.divider} />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              className={classes.uploadRow}
            >
              <Grid item xs={4} className={classes.uploadItem}>
                <FormControl fullWidth>
                  <InputLabel id="selectOption-label">Opção</InputLabel>
                  <Select
                    labelId="selectOption-label"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <MenuItem value="signup">Tela de Registro</MenuItem>
                    <MenuItem value="login">Logotipo Externo</MenuItem>
                    <MenuItem value="interno">Logotipo Interno</MenuItem>
                    <MenuItem value="favicon">Favicon.Ico</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.uploadItem}>
                <FormControl fullWidth>
                  <input
                    accept="image/png,image/x-icon,image/svg+xml"
                    className={classes.fileInput}
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="file-upload"
                    className={classes.fileInputLabel}
                  >
                    {selectedFileName
                      ? selectedFileName
                      : "Escolhe o arquivo PNG, ICO ou SVG"}
                  </label>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.uploadItem}>
                <ButtonWithSpinner
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Enviar
                </ButtonWithSpinner>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </CollapsibleCard>

      {/* Card de Configurações White Label */}
      <CollapsibleCard
        title="Configurações White Label"
        avatar={<Settings style={{ color: "#757575" }} />}
      >
        <Divider className={classes.divider} />
        <CardContent>
          <Grid container spacing={3}>
            {/* Dividindo em 2 colunas */}
            <Grid item xs={12} sm={6}>
              <Grid container direction="column" spacing={2}>
                {/* Coluna 1: Registro Visível, Cor e Título */}
                <Grid item>
                  <Typography variant="subtitle2">Título da Página:</Typography>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={titlePage}
                        onChange={handleTitlePageChange}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTitlePageSave}
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    Cor Primária Modo Claro:
                  </Typography>
                  <div
                    className={classes.swatchContainer}
                    onClick={handleOpenColorModal}
                  >
                    <Tooltip title={color || "#ffffff"}>
                      <div
                        className={classes.swatchCircle}
                        style={{ backgroundColor: color || "#ffffff" }}
                      />
                    </Tooltip>
                    <Typography variant="body2" style={{ marginLeft: 8 }}>
                      Alterar
                    </Typography>
                  </div>
                  <ColorBoxModal
                    open={primaryColorLightModalOpen}
                    handleClose={handleCloseColorModal}
                    onChange={handleChangeColor}
                    currentColor={color}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">Registro Visível?</Typography>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      onClick={(e) => e.stopPropagation()}
                      value={viewregister}
                      onChange={handleViewregisterChange}
                    >
                      <MenuItem value="disabled">Não</MenuItem>
                      <MenuItem value="enabled">Sim</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction="column" spacing={2}>
                {/* Coluna 2: Tempo de Trial, Bloqueio e Número de Suporte */}
                <Grid item>
                  <Typography variant="subtitle2">Tempo de Trial?</Typography>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      value={trial}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleTrialChange}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    Bloqueio após vencimento (dias)
                  </Typography>
                  <FormControl fullWidth variant="outlined" size="small">
                    <Select
                      value={blockuser}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleBlockChange}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">Número de Suporte</Typography>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={numeroSuporte}
                    onChange={handleNumeroSuporteChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>

      {/* Card de Configuração de Gateway */}
      <CollapsibleCard
        title="Configuração de Gateway"
        avatar={<Payment style={{ color: "#757575" }} />}
      >
        <Divider className={classes.divider} />
        <CardContent>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle2" align="center">
                Gateway Padrão
              </Typography>
              <FormControl fullWidth variant="outlined" size="small">
                <Select
                  value={defaultGateway}
                  onChange={handleDefaultGatewayChange}
                >
                  <MenuItem value="nenhum">Não usar Financeiro</MenuItem>
                  <MenuItem value="asaas">Asaas</MenuItem>
                  <MenuItem value="efi">Efi/Gerencianet</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>

      {/* Novo Card: Configuração Asaas */}
      {defaultGateway === "asaas" && (
        <CollapsibleCard
          title="Configuração Asaas"
          avatar={<Payment style={{ color: "#757575" }} />}
        >
          <Divider className={classes.divider} />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">
                  Ativar Gateway Asaas
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableAsaasGateway}
                      onChange={handleEnableAsaasChange}
                      color="primary"
                    />
                  }
                  label={enableAsaasGateway ? "Ativado" : "Desativado"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="subtitle2">Token Asaas</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="password"
                  value={asaasToken}
                  onChange={handleAsaasTokenChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAsaasSave}
                >
                  Salvar Asaas
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </CollapsibleCard>
      )}
    </div>
  );
};

export default Uploader;
