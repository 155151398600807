import React, { useState, useEffect, useContext } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { WhatsApp, Facebook, Instagram } from "@material-ui/icons";
import { Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
//import ShowTicketOpen from "../ShowTicketOpenModal";

const useStyles = makeStyles((theme) => ({
  online: {
    fontSize: 11,
    color: "#25d366",
  },
  offline: {
    fontSize: 11,
    color: "#e1306c",
  },
}));

const filter = createFilterOptions({
  trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const [newContact, setNewContact] = useState({});
  const [whatsapps, setWhatsapps] = useState([]);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { companyId, whatsappId } = user;

  const [openAlert, setOpenAlert] = useState(false);
  const [userTicketOpen, setUserTicketOpen] = useState("");
  const [queueTicketOpen, setQueueTicketOpen] = useState("");

  const [isBusinessAccount, setIsBusinessAccount] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (initialContact?.id !== undefined) {
      setOptions([initialContact]);
      setSelectedContact(initialContact);
    }
  }, [initialContact]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        api
          .get(`/whatsapp`, { params: { companyId, session: 0 } })
          .then(({ data }) => setWhatsapps(data));
      };

      if (whatsappId !== null && whatsappId !== undefined) {
        setSelectedWhatsapp(whatsappId);
      }

      if (user.queues.length === 1) {
        setSelectedQueue(user.queues[0].id);
      }
      fetchContacts();
      setLoading(false);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    const selectedWhatsApp = whatsapps.find((w) => w.id === selectedWhatsapp);
    if (selectedWhatsApp?.channel === "whatsapp_business_account") {
      setIsBusinessAccount(true);
      // Função para carregar os templates relacionados ao WhatsApp Business Account selecionado
      const fetchTemplates = async (whatsappId) => {
        try {
          const { data } = await api.get(
            `/whatsapp/listTemplate/${whatsappId}`
          );
          setTemplates(data);
        } catch (err) {
          toastError("Erro ao carregar templates");
        }
      };
      fetchTemplates(selectedWhatsApp.id);
    } else {
      setIsBusinessAccount(false);
      setTemplates([]);
    }
  }, [selectedWhatsapp, whatsapps]);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const IconChannel = (channel) => {
    switch (channel) {
      case "facebook":
        return (
          <Facebook style={{ color: "#3b5998", verticalAlign: "middle" }} />
        );
      case "instagram":
        return (
          <Instagram style={{ color: "#e1306c", verticalAlign: "middle" }} />
        );
      case "whatsapp":
        return (
          <WhatsApp style={{ color: "#25d366", verticalAlign: "middle" }} />
        );
      case "whatsapp_business_account":
        return (
          <WhatsApp style={{ color: "#25d366", verticalAlign: "middle" }} />
        );
      default:
        return "error";
    }
  };

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setOpenAlert(false);
    setUserTicketOpen("");
    setQueueTicketOpen("");
    setSelectedContact(null);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;

    // Para usuário não admin, é obrigatório selecionar uma fila
    if (selectedQueue === "" && user.profile !== "admin") {
      toast.error("Selecione uma fila");
      return;
    }

    setLoading(true);
    try {
      const whatsappIdParam = selectedWhatsapp !== "" ? selectedWhatsapp : null;

      // Função auxiliar para buscar ticket por status
      const getTicketByStatus = async (status) => {
        const response = await api.get("/tickets", {
          params: {
            contactId,
            companyId,
            whatsappId: whatsappIdParam,
            status,
          },
        });
        const data = response.data;
        if (!data) return null;
        if (Array.isArray(data)) return data[0] || null;
        if (data.tickets && Array.isArray(data.tickets))
          return data.tickets[0] || null;
        if (data.id) return data;
        return null;
      };

      // Tenta buscar tickets em ordem: open, pending e closed
      let existingTicket =
        (await getTicketByStatus("open")) ||
        (await getTicketByStatus("pending")) ||
        (await getTicketByStatus("closed"));

      // Se já existir um ticket...
      if (existingTicket) {
        if (user.profile === "admin") {
          // Admin pode abrir ticket independentemente do status
          const { data: ticket } = await api.post("/tickets", {
            contactId,
            queueId: selectedQueue,
            whatsappId: whatsappIdParam,
            userId: user.id,
            status: "open",
            channel: whatsapps.channel,
          });
          toast.success("Transferindo o Ticket para você.");
          resetFields();
          setLoading(false);
          onClose(ticket);
          return;
        } else {
          // Usuário não admin só pode abrir se o ticket existente NÃO estiver open
          if (existingTicket.status === "open") {
            toast.warn(
              `Ticket já aberto para o usuário ${existingTicket.user.name}. Solicite que ele transfira o ticket para você.`
            );
            resetFields();
            setLoading(false);
            onClose();
            return;
          }
          // Se estiver pending ou closed, permite abrir um novo ticket
          const { data: ticket } = await api.post("/tickets", {
            contactId,
            queueId: selectedQueue,
            whatsappId: whatsappIdParam,
            userId: user.id,
            status: "open",
            channel: whatsapps.channel,
          });
          toast.success("Ticket aberto com sucesso.");
          resetFields();
          setLoading(false);
          onClose(ticket);
          return;
        }
      }

      // Se nenhum ticket existente for encontrado, cria um novo
      const { data: ticket } = await api.post("/tickets", {
        contactId,
        queueId: selectedQueue !== "" ? selectedQueue : null,
        whatsappId: whatsappIdParam,
        userId: user.id,
        status: "open",
        channel: whatsapps.channel,
      });

      // Se o canal for do WhatsApp Business, envia template
      if (ticket.channel === "whatsapp_business_account") {
        await api.post(`/messages/template/${ticket.id}`, {
          templateName: selectedTemplate,
        });
        toast.success("Template enviado com sucesso!");
      }

      toast.success("Ticket criado com sucesso!");
      resetFields();
      onClose(ticket);
    } catch (err) {
      console.log("🚀 ~ handleSaveTicket ~ err:", err);
      toast.error("Erro inesperado ao criar o ticket.");
      toastError(err);
    }
    setLoading(false);
  };

  const resetFields = () => {
    setSearchParam("");
    setSelectedContact(null);
    setSelectedQueue("");
    setSelectedWhatsapp("");
    setNewContact({});
    setOpenAlert(false);
    setUserTicketOpen("");
    setQueueTicketOpen("");
  };

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSelectedContact(newValue);
    } else if (newValue?.name) {
      setNewContact({ name: newValue.name });
      setContactModalOpen(true);
    }
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };

  const handleAddNewContactTicket = (contact) => {
    handleSaveTicket(contact.id);
  };

  const createAddContactOption = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);
    if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
      filtered.push({
        name: `${params.inputValue}`,
      });
    }
    return filtered;
  };

  const renderOption = (option) => {
    if (option.number) {
      return (
        <>
          {IconChannel(option.channel)}
          <Typography
            component="span"
            style={{
              fontSize: 14,
              marginLeft: "10px",
              display: "inline-flex",
              alignItems: "center",
              lineHeight: "2",
            }}
          >
            {option.name} - {option.number}
          </Typography>
        </>
      );
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };

  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const renderContactAutocomplete = () => {
    if (initialContact === undefined || initialContact.id === undefined) {
      return (
        <Grid xs={12} item>
          <Autocomplete
            fullWidth
            options={options}
            loading={loading}
            clearOnBlur
            autoHighlight
            freeSolo
            clearOnEscape
            getOptionLabel={renderOptionLabel}
            renderOption={renderOption}
            filterOptions={createAddContactOption}
            onChange={(e, newValue) => handleSelectOption(e, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n.t("newTicketModal.fieldLabel")}
                variant="outlined"
                autoFocus
                onChange={(e) => setSearchParam(e.target.value)}
                onKeyPress={(e) => {
                  if (loading || !selectedContact) return;
                  else if (e.key === "Enter") {
                    handleSaveTicket(selectedContact.id);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <ContactModal
        open={contactModalOpen}
        initialValues={newContact}
        onClose={handleCloseContactModal}
        onSave={handleAddNewContactTicket}
      ></ContactModal>
      <Dialog open={modalOpen} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("newTicketModal.title")}
        </DialogTitle>
        <DialogContent dividers>
          <Grid style={{ width: 300 }} container spacing={2}>
            {/* CONTATO */}
            {renderContactAutocomplete()}
            {/* FILA */}
            <Grid xs={12} item>
              <Select
                required
                fullWidth
                displayEmpty
                variant="outlined"
                value={selectedQueue}
                onChange={(e) => {
                  setSelectedQueue(e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={() => {
                  if (selectedQueue === "") {
                    return "Selecione uma fila";
                  }
                  const queue = user.queues.find((q) => q.id === selectedQueue);
                  return queue.name;
                }}
              >
                {user.queues?.length > 0 &&
                  user.queues.map((queue, key) => (
                    <MenuItem dense key={key} value={queue.id}>
                      <ListItemText primary={queue.name} />
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {/* CONEXAO */}
            <Grid xs={12} item>
              <Select
                required
                fullWidth
                displayEmpty
                variant="outlined"
                value={selectedWhatsapp}
                onChange={(e) => {
                  setSelectedWhatsapp(e.target.value);
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={() => {
                  if (selectedWhatsapp === "") {
                    return "Selecione uma Conexão";
                  }
                  const whatsapp = whatsapps.find(
                    (w) => w.id === selectedWhatsapp
                  );
                  return whatsapp.name;
                }}
              >
                {whatsapps?.length > 0 &&
                  whatsapps.map((whatsapp, key) => (
                    <MenuItem dense key={key} value={whatsapp.id}>
                      <ListItemText
                        primary={
                          <>
                            {IconChannel(whatsapp.channel)}
                            <Typography
                              component="span"
                              style={{
                                fontSize: 14,
                                marginLeft: "10px",
                                display: "inline-flex",
                                alignItems: "center",
                                lineHeight: "2",
                              }}
                            >
                              {whatsapp.name} &nbsp;{" "}
                              <p
                                className={
                                  whatsapp.status === "CONNECTED"
                                    ? classes.online
                                    : classes.offline
                                }
                              >
                                (
                                {whatsapp.status === "CONNECTED"
                                  ? "Conectado"
                                  : "Desconectado"}
                                )
                              </p>
                            </Typography>
                          </>
                        }
                      />
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {/* TEMPLATE */}
            {isBusinessAccount && (
              <Grid xs={12} item>
                <Select
                  required
                  fullWidth
                  variant="outlined"
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                  displayEmpty
                  renderValue={() =>
                    selectedTemplate
                      ? selectedTemplate
                      : "Selecione um Template"
                  }
                >
                  {templates.map((template) => (
                    <MenuItem key={template.id} value={template.name}>
                      {template.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("newTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="button"
            disabled={!selectedContact}
            onClick={() => handleSaveTicket(selectedContact.id)}
            color="primary"
            loading={loading}
          >
            {i18n.t("newTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
        {/* <ShowTicketOpen
          isOpen={openAlert}
          handleClose={handleCloseAlert}
          user={userTicketOpen}
          queue={queueTicketOpen}
			  /> */}
      </Dialog>
    </>
  );
};
export default NewTicketModal;
