import React, { useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
}) => {
  // Garante que "Sem Fila" esteja incluído na inicialização
  /* useEffect(() => {
    if (!selectedQueueIds.includes(null)) {
      onChange([null, ...selectedQueueIds]); // Adiciona `null` à lista inicial
    }
  }, []); // Executa apenas na montagem */

  const handleChange = (e) => {
    let values = e.target.value;
    // Se o usuário desmarcar todas as filas, retorna para o padrão (todas as filas do usuário)
    if (values.length === 0) {
      values = userQueues.map((queue) => queue.id);
    }
    onChange(values);
  };

  return (
    <div style={{ width: 120, marginTop: -4 }}>
      <FormControl fullWidth margin="dense">
        <Select
          style={{ height: 30 }}
          multiple
          displayEmpty
          variant="outlined"
          value={selectedQueueIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={(selected) =>
            selected.length > 0
              ? selected
                  .map((id) =>
                    id === null
                      ? i18n.t("Sem Fila")
                      : userQueues.find((queue) => queue.id === id)?.name
                  )
                  .join(", ")
              : i18n.t("ticketsQueueSelect.placeholder")
          }
        >
          {/* Adicionar "Sem Fila" como uma opção */}
          <MenuItem dense value={null}>
            <Checkbox
              style={{
                color: "#aaa",
              }}
              size="small"
              color="primary"
              checked={selectedQueueIds.includes(null)}
            />
            <ListItemText primary={i18n.t("Sem Fila")} />
          </MenuItem>

          {/* Renderizar filas existentes */}
          {userQueues?.length > 0 &&
            userQueues.map((queue) => (
              <MenuItem dense key={queue.id} value={queue.id}>
                <Checkbox
                  style={{
                    color: queue.color,
                  }}
                  size="small"
                  color="primary"
                  checked={selectedQueueIds.includes(queue.id)}
                />
                <ListItemText primary={queue.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TicketsQueueSelect;
