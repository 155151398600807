import React, { useState, useEffect, useContext } from "react";
import clsx from 'clsx';
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from '@mui/icons-material/Today';
import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';

import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { grey, blue } from "@mui/material/colors";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import CardCounter from "../../components/Dashboard/CardCounter";
import UpdatesModal from "../../components/Dashboard/UpdatesModal ";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";
import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";
import { isEmpty } from "lodash";
import moment from "moment";
import { Facebook, Instagram, Telegram, WhatsApp } from "@material-ui/icons";
import InstagramIcon from '@mui/icons-material/Instagram';
import { AuthContext } from "../../context/Auth/AuthContext";
import { SYSTEM_VERSION } from "../../utils/version";
import Cards from "./Cards";


const theme = createTheme({
  spacing: 8, // define a unidade de espaçamento
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em'
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.1)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.2)',
            outline: '1px solid slategrey'
          }
        }
      }
    }
  }
});

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    height: 272,
    overflowY: "auto",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.default,
  },
  selectContainer: {
    width: '100%',
    height: '40px',
    '& .MuiInputBase-root': {
      height: '40px',
      lineHeight: '40px',
    },
    '& .MuiInputLabel-root': {
      lineHeight: '40px',
    },
    '& input': {
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  icon: {
    fontSize: "32px",
    color: blue[700],
  },
  title: {
    fontSize: "16px",
    color: blue[700],
  },
  value: {
    fontSize: "24px",
    color: grey[800],
  },

  cardMinimal: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",  // Alinhando os itens em linha
    alignItems: "center",
    justifyContent: "flex-start",  // Alinhamento à esquerda
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
    marginBottom: theme.spacing(1),
  },
  cardMinimalFilter: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",  // Alinhando os itens em linha
    alignItems: "center",
    justifyContent: "flex-start",  // Alinhamento à esquerda
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: "45px",
    color: blue[700],
    marginRight: theme.spacing(2),  // Espaço entre o ícone e o texto
  },
  title: {
    fontSize: "16px",
    color: grey[800],
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  value: {
    fontSize: "20px",
    color: grey[900],
    fontWeight: 700,
  },
  fullWidth: {
    width: '100%', // Certifique-se de que o componente ocupa 100% da largura
  },
  width70: {
    width: '70%', // Certifique-se de que o componente ocupa 100% da largura
  },
  instagramGradient: {
    width: 45, // tamanho em px
    height: 45, // tamanho em px
    marginRight: theme.spacing(2),  // Espaço entre o ícone e o texto
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)',
    mask: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"%23FFF\" d=\"M7 2h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5zm3 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 1h1v2h-1V5zm-3 2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6z\"/></svg>') center / contain no-repeat",
    WebkitMask: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\"><path fill=\"%23FFF\" d=\"M7 2h10a5 5 0 0 1 5 5v10a5 5 0 0 1-5 5H7a5 5 0 0 1-5-5V7a5 5 0 0 1 5-5zm3 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 1h1v2h-1V5zm-3 2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 2a3 3 0 1 1 0 6 3 3 0 0 1 0-6z\"/></svg>') center / contain no-repeat",
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [channelCountWhatsApp, setChannelCountWhatsApp] = useState({
    ticket_count_today: 0,
    ticket_count_yesterday: 0,
    percentage_change: 0,
  });
  const [channelCountFacebook, setChannelCountFacebook] = useState({
    ticket_count_today: 0,
    ticket_count_yesterday: 0,
    percentage_change: 0,
  });
  const [channelCountInstagram, setChannelCountInstagram] = useState({
    ticket_count_today: 0,
    ticket_count_yesterday: 0,
    percentage_change: 0,
  });
  const [channelCountTelegram, setChannelCountTelegram] = useState({
    ticket_count_today: 0,
    ticket_count_yesterday: 0,
    percentage_change: 0,
  });


  const [attendants, setAttendants] = useState([]);
  const [filterType, setFilterType] = useState(1);
  const [period, setPeriod] = useState(0);
  const [companyDueDate, setCompanyDueDate] = useState();
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find, channel } = useDashboard();
  const { finding } = useCompanies();
  const { user } = useContext(AuthContext);

  const [isPopupOpen, setIsPopupOpen] = useState(() => {
    const lastDisplayedVersion = localStorage.getItem("modalDisplayedVersion");
    return user.profile === "admin" && lastDisplayedVersion !== SYSTEM_VERSION;
  });

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    localStorage.setItem("modalDisplayedVersion", SYSTEM_VERSION); // Salva a versão atual no localStorage
  };

  useEffect(() => {
    if (isPopupOpen) {
    }
  }, [isPopupOpen]);


  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {
      date_from: moment().subtract(1, "days").format("YYYY-MM-DD"), // Ontem
      date_to: moment().format("YYYY-MM-DD") // Hoje
    };

    // Log para depuração

    // Faz a busca com os parâmetros ajustados
    const data = await find(params);

    const channelFacebook = await channel(companyId, "facebook");
    const channelInstagram = await channel(companyId, "instagram");
    const channelTelegram = await channel(companyId, "telegram");
    const channelWhats = await channel(companyId, "whatsapp");

    setChannelCountWhatsApp({
      ticket_count_today: channelWhats.ticket_count_today,
      ticket_count_yesterday: channelWhats.ticket_count_yesterday,
      percentage_change: channelWhats.percentage_change,
    });

    setChannelCountFacebook({
      ticket_count_today: channelFacebook.ticket_count_today,
      ticket_count_yesterday: channelFacebook.ticket_count_yesterday,
      percentage_change: channelFacebook.percentage_change,
    });

    setChannelCountInstagram({
      ticket_count_today: channelInstagram.ticket_count_today,
      ticket_count_yesterday: channelInstagram.ticket_count_yesterday,
      percentage_change: channelInstagram.percentage_change,
    });

    setChannelCountTelegram({
      ticket_count_today: channelTelegram.ticket_count_today,
      ticket_count_yesterday: channelTelegram.ticket_count_yesterday,
      percentage_change: channelTelegram.percentage_change,
    });



    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  useEffect(() => {
    async function fetchData() {
      await loadCompanies();
    }
    fetchData();
  }, [])
  //let companyDueDate = localStorage.getItem("companyDueDate");
  //const companyDueDate = localStorage.getItem("companyDueDate").toString();
  const companyId = localStorage.getItem("companyId");
  const loadCompanies = async () => {
    setLoading(true);
    try {
      const companiesList = await finding(companyId);
      setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
    } catch (e) {
      // toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <TextField
                  label="Data Inicial"
                  type="date"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  className={classes.selectContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </Paper>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <TextField
                  label="Data Final"
                  type="date"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  className={classes.selectContainer}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </div>
          </Paper>
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12}>
          <Paper elevation={3} className={clsx(classes.cardMinimalFilter, classes.fullWidth)}>
            <TodayIcon className={classes.icon} />
            <div className={classes.fullWidth}>
              <FormControl className={classes.selectContainer}>
                <InputLabel id="period-selector-label">Período</InputLabel>
                <Select
                  labelId="period-selector-label"
                  id="period-selector"
                  value={period}
                  onChange={(e) => handleChangePeriod(e.target.value)}
                >
                  <MenuItem value={0}>Nenhum selecionado</MenuItem>
                  <MenuItem value={3}>Últimos 3 dias</MenuItem>
                  <MenuItem value={7}>Últimos 7 dias</MenuItem>
                  <MenuItem value={15}>Últimos 15 dias</MenuItem>
                  <MenuItem value={30}>Últimos 30 dias</MenuItem>
                  <MenuItem value={60}>Últimos 60 dias</MenuItem>
                  <MenuItem value={90}>Últimos 90 dias</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Paper>
          <Grid item xs={12} className={classes.alignRight}>
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
        </Grid>
      );
    }
  }

  const AnimatedTicketCount = ({ ticketCountToday, loading }) => {
    const [displayedCount, setDisplayedCount] = useState(0);

    useEffect(() => {
      if (loading) {
        setDisplayedCount(0); // Reset to 0 while loading
      } else {
        let start = 0;
        let end = ticketCountToday;
        let step = (end - start) / 100; // Adjust the speed of increment
        let current = start;

        const animate = () => {
          current += step;
          if (current >= end) {
            current = end; // Stop at the final value
          }
          setDisplayedCount(current);
          if (current < end) {
            requestAnimationFrame(animate);
          }
        };

        animate();
      }
    }, [ticketCountToday, loading]);

    return (
      <Typography style={{ fontSize: 24, fontWeight: 'bold', color: '#333' }}>
        {loading ? <CircularProgress size={24} /> : <strong>{Math.round(displayedCount)}</strong>}
      </Typography>
    );
  };

  const PercentageCounter = ({ percentageChange, loading }) => {
    const [displayedPercentage, setDisplayedPercentage] = useState(0);

    useEffect(() => {
      if (loading) {
        setDisplayedPercentage(0); // Reset to 0 while loading
      } else {
        let start = 0;
        let end = percentageChange;
        let step = (end - start) / 100; // Control the speed of animation
        let current = start;

        const animate = () => {
          current += step;
          if (Math.abs(current) >= Math.abs(end)) {
            current = end; // Stop at the final value
          }
          setDisplayedPercentage(current);
          if (Math.abs(current) < Math.abs(end)) {
            requestAnimationFrame(animate);
          }
        };

        animate();
      }
    }, [percentageChange, loading]);

    return (
      <Typography style={{ fontSize: 14, color: displayedPercentage >= 0 ? 'green' : 'red', marginLeft: '8px' }}>
        {loading ? '0%' : `${displayedPercentage >= 0 ? '+' : ''}${Math.round(displayedPercentage)}%`}
      </Typography>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <UpdatesModal open={isPopupOpen} onClose={handleClosePopup} />

      <Container maxWidth="100%" className={classes.container}> {/* Removeu o ajuste de maxWidth */}
        <Grid container spacing={3} justifyContent="flex-end"> {/* Aumenta o espaçamento entre os itens */}
          {/* Card do WhatsApp */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper
              elevation={3}
              className={classes.cardMinimal}
              style={{
                padding: '16px',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.mode === "light" ? theme.palette.fancyBackground : theme.palette.dark.main, // Cor do fundo do card
              }}
            >
              <div
                style={{
                  backgroundColor: theme.palette.mode === "light" ? "#25D366" : "#128C7E", // Cor do ícone do WhatsApp (verde claro no modo claro, verde escuro no modo escuro)
                  borderRadius: "50%",
                  fontSize: "32px",
                  padding: "10px",
                  color: "white",
                  marginRight: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WhatsApp style={{ fontSize: "24px" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  color: theme.palette.textPrimary, // Ajusta o texto conforme o tema (garante a cor correta do texto)
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    color: theme.palette.mode === "light" ? "#555" : "#ddd", // Cor do título: cinza claro no tema claro e cinza mais claro no tema escuro
                    fontWeight: 500,
                  }}
                  className={classes.title}
                >
                  WhatsApp
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AnimatedTicketCount
                    ticketCountToday={channelCountWhatsApp.ticket_count_today}
                    loading={loading}
                  />
                  <PercentageCounter
                    percentageChange={channelCountWhatsApp.percentage_change}
                    loading={loading}
                  />
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Card do Facebook */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} className={classes.cardMinimal} style={{ padding: '16px', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
              <div style={{
                backgroundColor: '#3b5998',
                borderRadius: '50%',
                fontSize: '32px',
                padding: '10px',
                color: 'white',
                marginRight: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Facebook style={{ fontSize: '24px' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <Typography style={{ fontSize: 16, color: '#555', fontWeight: 500 }} className={classes.title}>
                  Facebook
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AnimatedTicketCount ticketCountToday={channelCountFacebook.ticket_count_today} loading={loading} />
                  <PercentageCounter percentageChange={channelCountFacebook.percentage_change} loading={loading} />
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Card do Instagram */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} className={classes.cardMinimal} style={{ padding: '16px', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
              <div style={{
                backgroundColor: '#E1306C', // Cor do fundo do Instagram
                borderRadius: '50%',
                fontSize: '32px',
                padding: '10px',
                color: 'white',
                marginRight: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <InstagramIcon style={{ fontSize: '24px' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <Typography style={{ fontSize: 16, color: '#555', fontWeight: 500 }} className={classes.title}>
                  Instagram
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AnimatedTicketCount ticketCountToday={channelCountInstagram.ticket_count_today} loading={loading} />
                  <PercentageCounter percentageChange={channelCountInstagram.percentage_change} loading={loading} />
                </div>
              </div>
            </Paper>
          </Grid>

          {/* Card do Telegram */}
          <Grid item xs={12} sm={6} md={3}>
            <Paper elevation={3} className={classes.cardMinimal} style={{ padding: '16px', borderRadius: '12px', display: 'flex', alignItems: 'center' }}>
              <div style={{
                backgroundColor: '#0088cc',
                borderRadius: '50%',
                fontSize: '32px',
                padding: '10px',
                color: 'white',
                marginRight: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Telegram style={{ fontSize: '24px' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                <Typography style={{ fontSize: 16, color: '#555', fontWeight: 500 }} className={classes.title}>
                  Telegram
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AnimatedTicketCount ticketCountToday={channelCountTelegram.ticket_count_today} loading={loading} />
                  <PercentageCounter percentageChange={channelCountTelegram.percentage_change} loading={loading} />
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} style={{ display: "flex", alignItems: "stretch", height: "100%" }}>
              <Grid item xs={12} md={3} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Cards loading={loading} counters={counters} />
              </Grid>
              <Grid item xs={12} md={9} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Chart />
              </Grid>
            </Grid>
          </Grid>


        </Grid>
      </Container >
    </ThemeProvider >

  );
};

export default Dashboard;
