import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import { MenuItem, FormControl, InputLabel, Select } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { InputAdornment, IconButton } from "@material-ui/core";
import QueueSelectSingle from "../../components/QueueSelectSingle";
import Alert from "@material-ui/lab/Alert";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const PromptSchema = Yup.object().shape({
  name: Yup.string()
    .min(5, "Muito curto!")
    .max(100, "Muito longo!")
    .required("Obrigatório"),
  prompt: Yup.string()
    .min(50, "Muito curto!")
    .required("Descreva o treinamento para Inteligência Artificial"),
  voice: Yup.string().required("Informe o modo para Voz"),
  max_tokens: Yup.number().required("Informe o número máximo de tokens"),
  temperature: Yup.number().required("Informe a temperatura"),
  apikey: Yup.string().required("Informe a API Key"),
  queueId: Yup.number().required("Informe a fila"),
  max_messages: Yup.number().required("Informe o número máximo de mensagens"),
});

const PromptModal = ({ open, onClose, promptId }) => {
  const classes = useStyles();
  const [selectedVoice, setSelectedVoice] = useState("texto");
  const [selectedType, setSelectedType] = useState("texto");
  const [selectedGPT, setSelectedGPT] = useState("texto");
  const [showApiKey, setShowApiKey] = useState(false);

  const handleToggleApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const initialState = {
    name: "",
    prompt: "",
    voice: "texto",
    modelGPT: "",
    voiceKey: "",
    voiceRegion: "",
    maxTokens: 100,
    temperature: 1,
    apiKey: "",
    queueId: null,
    maxMessages: 10,
  };

  const elevenLabsVoices = [
    { id: "33B4UnXyTNbgLmdEDh5P", name: "Keren" },
    { id: "36rVQA1AOIPwpA3Hg1tC", name: "Matheus" },
    { id: "9BWtsMINqrJLrRacOk9x", name: "Aria" },
    { id: "CwhRBWXzGAHq8TQ4Fs17", name: "Roger" },
    { id: "EXAVITQu4vr4xnSDxMaL", name: "Sarah" },
    { id: "FGY2WhTYpPnrIDTdsKH5", name: "Laura" },
    { id: "IKne3meq5aSn9XLyUdCD", name: "Charlie" },
    { id: "JBFqnCBsd6RMkjVDRZzb", name: "George" },
    { id: "N2lVS1w4EtoT3dr4eOWO", name: "Callum" },
    { id: "SAz9YHcvj6GT2YYXdXww", name: "River" },
    { id: "TX3LPaxmHKxFdv7VOQHJ", name: "Liam" },
    { id: "XB0fDUnXU5powFXDhCwa", name: "Charlotte" },
    { id: "Xb7hH8MSUJpSbSDYk0k2", name: "Alice" },
    { id: "XrExE9yKIg1WjnnlVkGX", name: "Matilda" },
    { id: "bIHbv24MWmeRgasZH58o", name: "Will" },
    { id: "cgSgspJ2msm6clMCkdW9", name: "Jessica" },
    { id: "cjVigY5qzO86Huf0OWal", name: "Eric" },
    { id: "iP95p4xoKVk53GoZ742B", name: "Chris" },
    { id: "nPczCjzI2devNBz1zQrb", name: "Brian" },
    { id: "onwK4e9ZLuTAKqWW03F9", name: "Daniel" },
    { id: "pFZP5JQG7iQjIQuC4Bku", name: "Lily" },
    { id: "pqHfZKP75CvOlQylNhV4", name: "Bill" },
  ];

  const [prompt, setPrompt] = useState(initialState);

  useEffect(() => {
    const fetchPrompt = async () => {
      if (!promptId) {
        setPrompt(initialState);
        return;
      }
      try {
        const { data } = await api.get(`/prompt/${promptId}`);
        setPrompt((prevState) => {
          return { ...prevState, ...data };
        });
        setSelectedVoice(data.voice);
        setSelectedGPT(data.modelGPT);
      } catch (err) {
        toastError(err);
      }
    };

    fetchPrompt();
  }, [promptId, open]);

  const handleClose = () => {
    setPrompt(initialState);
    setSelectedVoice("texto");
    setSelectedGPT("");
    onClose();
  };

  const handleChangeType = (e) => {
    setSelectedType(e.target.value);
  };

  const handleChangeVoice = (e) => {
    setSelectedVoice(e.target.value);
  };

  const handleChangeGPT = (e) => {
    setSelectedGPT(e.target.value);
  };

  const handleSavePrompt = async (values) => {
    const promptData = {
      ...values,
      voice: selectedVoice,
      voiceRegion: selectedType,
      modelGPT: selectedGPT,
    };
    if (!values.queueId) {
      toastError("Informe o setor");
      return;
    }
    try {
      if (promptId) {
        await api.put(`/prompt/${promptId}`, promptData);
      } else {
        await api.post("/prompt", promptData);
      }
      toast.success(i18n.t("promptModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        scroll="paper"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {promptId
            ? `${i18n.t("promptModal.title.edit")}`
            : `${i18n.t("promptModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={prompt}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSavePrompt(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form style={{ width: "100%" }}>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t("promptModal.form.name")}
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <FormControl fullWidth margin="dense" variant="outlined">
                  <Field
                    as={TextField}
                    label={i18n.t("promptModal.form.apikey")}
                    name="apiKey"
                    type={showApiKey ? "text" : "password"}
                    error={touched.apiKey && Boolean(errors.apiKey)}
                    helperText={touched.apiKey && errors.apiKey}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleToggleApiKey}>
                            {showApiKey ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Field
                  as={TextField}
                  label={i18n.t("promptModal.form.prompt")}
                  name="prompt"
                  error={touched.prompt && Boolean(errors.prompt)}
                  helperText={touched.prompt && errors.prompt}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rows={10}
                  multiline={true}
                />
                <Grid item xs={12} sm={12}>
                  <Alert severity="warning" style={{ marginTop: 16 }}>
                    <b>Atenção</b>: Ao utilizar a funcionalidade de voz com{" "}
                    <b>Elevenlabs</b>, você pode incorrer em custos adicionais
                    que variam conforme o volume de uso e as configurações
                    escolhidas. Recomendamos que você revise atentamente os
                    termos de uso e a política de cobrança da <b>Elevenlabs</b>.
                    Para configurar o serviço corretamente, é necessário gerar
                    uma API Key. Clique{" "}
                    <a
                      href="https://elevenlabs.io/app/settings/api-keys"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      aqui para gerar sua API Key
                    </a>{" "}
                    e obter mais informações.
                  </Alert>
                </Grid>
                <Grid container spacing={2}>
                  {/* Fila */}
                  <Grid item xs={12} sm={6}>
                    <QueueSelectSingle />
                  </Grid>

                  {/* Voz */}
                  <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: 6 }}>
                      <FormControl
                        variant="outlined"
                        className={classes.FormControl}
                        margin="dense"
                        fullWidth
                      >
                        <div>
                          <Typography>{i18n.t("Modelo GPT")}</Typography>
                          <Field
                            required
                            as={Select}
                            name="modelGPT"
                            labelId="model-gpt-selection-label"
                            id="modelGPT"
                            fullWidth
                            value={selectedGPT}
                            onChange={handleChangeGPT}
                          >
                            {[
                              {
                                key: "chatgpt-4o-latest",
                                value: "chatgpt-4o-latest",
                                label: "GPT-4o (Versão atual ChatGPT)",
                              },
                              {
                                key: "gpt-4o-mini",
                                value: "gpt-4o-mini",
                                label: "GPT-4 Mini",
                              },
                              {
                                key: "gpt-4o",
                                value: "gpt-4o",
                                label: "GPT-4o",
                              },
                              {
                                key: "gpt-3.5-turbo",
                                value: "gpt-3.5-turbo",
                                label: "GPT-3.5 Turbo",
                              },
                            ].map((option) => (
                              <MenuItem key={option.key} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </div>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.multFieldLine}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>{i18n.t("Tipo de Resposta")}</InputLabel>
                    <Select
                      id="type-select"
                      name="voice-type"
                      value={selectedType}
                      onChange={handleChangeType}
                      multiple={false}
                    >
                      <MenuItem key="auto" value="auto">
                        Automático
                      </MenuItem>
                      <MenuItem key="texto" value="texto">
                        Texto
                      </MenuItem>
                      <MenuItem key="voz" value="voz">
                        Voz
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>{i18n.t("promptModal.form.voice")}</InputLabel>
                    <Select
                      id="type-select"
                      name="voice"
                      value={selectedVoice}
                      onChange={handleChangeVoice}
                      multiple={false}
                    >
                      {elevenLabsVoices.map((voice) => (
                        <MenuItem key={voice.id} value={voice.id}>
                          {voice.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Field
                    as={TextField}
                    label={i18n.t("promptModal.form.voiceKey")}
                    name="voiceKey"
                    error={touched.voiceKey && Boolean(errors.voiceKey)}
                    helperText={touched.voiceKey && errors.voiceKey}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>

                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("promptModal.form.temperature")}
                    name="temperature"
                    error={touched.temperature && Boolean(errors.temperature)}
                    helperText={touched.temperature && errors.temperature}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("promptModal.form.max_tokens")}
                    name="maxTokens"
                    error={touched.maxTokens && Boolean(errors.maxTokens)}
                    helperText={touched.maxTokens && errors.maxTokens}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("promptModal.form.max_messages")}
                    name="maxMessages"
                    error={touched.maxMessages && Boolean(errors.maxMessages)}
                    helperText={touched.maxMessages && errors.maxMessages}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("promptModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {promptId
                    ? `${i18n.t("promptModal.buttons.okEdit")}`
                    : `${i18n.t("promptModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PromptModal;
