import {
    ArrowForwardIos,
    ContentCopy,
    Delete,
} from "@mui/icons-material";
import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import Http from '@mui/icons-material/Http';
import { Typography } from "@mui/material";
import { useNodeStorage } from "../../../stores/useNodeStorage";

export default memo(({ data, isConnectable, id }) => {
    const storageItems = useNodeStorage();

    const requestData = data?.data || data?.data?.data;
    const method = data?.method || requestData?.method;
    const url = data?.url || requestData?.url;

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                padding: "8px",
                borderRadius: "8px",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 5px",
                border: '1px solid rgba(33, 94, 151, 0.25)',
            }}
        >
            <Handle
                type="target"
                position="left"
                style={{
                    background: "#0872b9",
                    width: "18px",
                    height: "18px",
                    top: "20px",
                    left: "-12px",
                    cursor: 'pointer',
                }}
                //onConnect={params => //console.log("handle onConnect", params)}
                isConnectable={isConnectable}
            >
                <ArrowForwardIos
                    sx={{
                        color: "#ffff",
                        width: "10px",
                        height: "10px",
                        marginLeft: "2.9px",
                        marginBottom: "1px",
                        pointerEvents: 'none',
                    }}
                />
            </Handle>
            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    right: 5,
                    top: 5,
                    cursor: "pointer",
                    gap: 6,
                }}
            >
                {/* <ContentCopy
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("duplicate");
                    }}
                    sx={{ width: "12px", height: "12px", color: "#F7953B" }}
                /> */}
                <Delete
                    onClick={() => {
                        storageItems.setNodesStorage(id);
                        storageItems.setAct("delete");
                    }}
                    sx={{ width: "12px", height: "12px", color: "#F7953B" }}
                />
            </div>
            <div
                style={{
                    color: "#ededed",
                    fontSize: "16px",
                    flexDirection: "row",
                    display: "flex",
                }}
            >
                <Http
                    sx={{
                        width: "16px",
                        height: "16px",
                        marginRight: "4px",
                        marginTop: "4px",
                        color: "#3aba38",
                    }}
                />
                <div style={{ color: "#232323", fontSize: "16px" }}>
                    HTTP Request
                </div>
            </div>
            <div style={{ marginTop: "10px", fontSize: "12px", color: "#232323" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {method.toUpperCase()}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        wordWrap: "break-word",
                        color: "#555",
                        fontSize: "12px",
                        marginTop: "4px",
                    }}
                >
                    {url}
                </Typography>
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                style={{
                    background: "#0872b9",
                    width: "18px",
                    height: "18px",
                    top: "70%",
                    right: "-11px",
                    cursor: 'pointer',
                }}
                isConnectable={isConnectable}
            >
                <ArrowForwardIos
                    sx={{
                        color: "#ffff",
                        width: "10px",
                        height: "10px",
                        marginLeft: "2.9px",
                        marginBottom: "1px",
                        pointerEvents: "none",
                    }}
                />
            </Handle>
        </div>
    );
});
